@tailwind base;
@tailwind components;
@tailwind utilities;

*:focus-visible {
  outline: initial;
}

html.dark, body.dark {
  @apply bg-slate-900;
}

@font-face {
  font-family: "Eina";
  font-style: normal;
  font-weight: 300;
  src:
    local("Eina 02"),
    local("eina-02"),
    url("./fonts/eina-02/eina-02-light.woff") format("truetype");
}

@font-face {
  font-family: "Eina";
  font-style: normal;
  font-weight: 400;
  src:
    local("Eina 02"),
    local("eina-02"),
    url("./fonts/eina-02/eina-02-regular.woff") format("truetype");
}

@font-face {
  font-family: "Eina";
  font-style: normal;
  font-weight: 600;
  src:
    local("Eina 02"),
    local("eina-02"),
    url("./fonts/eina-02/eina-02-semibold.woff") format("truetype");
}

@font-face {
  font-family: "Eina";
  font-style: normal;
  font-weight: 700;
  src:
    local("Eina 02"),
    local("eina-02"),
    url("./fonts/eina-02/eina-02-bold.woff") format("truetype");
}

@font-face {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Dancing-Script.ttf") format("truetype");
}

.text-upscope-gradient {
  @apply bg-gradient-to-r from-[#ea80ff] to-[#8c57f6] bg-clip-text text-transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.text-upscope-gradient-marquee {
  @apply bg-gradient-to-r from-[#ea80ff] via-[#8c57f6] via-50% to-[#ea80ff] bg-clip-text text-transparent;
  animation: move-upscope-gradient-background 10s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
}

.underlined-links > a {
  text-decoration: underline;
  pointer: cursor;
}

.bg-dots {
  background:
    linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center /
      var(--dot-space) var(--dot-space),
    linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space)
      var(--dot-space),
    var(--dot-color);
}

.shadow-multi {
  box-shadow:
    0 0 0 1px rgb(0 0 0 / 0.1),
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.clickable {
  @apply cursor-pointer transition-transform hover:scale-105 active:scale-95;
}

.normal-lists > ul,
.normal-lists > ol {
  list-style-position: outside;
  margin-left: 1.5em;
}

.normal-lists > ul {
  list-style-type: disc;
}

.normal-lists > ol {
  list-style-type: decimal;
}

.normal-lists > ol > li,
.normal-lists > ul > li {
  margin-bottom: 1em;
}

@keyframes float-animation {
  0% {
    transform: translateX(-5%);
  }

  50% {
    transform: translate(5%);
  }
}

@keyframes move-upscope-gradient-background {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 1000px;
  }
}

.animate-float {
  animation: float-animation 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}

.animate-blink {
  animation: blink-animation 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}
