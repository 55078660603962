*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Eina, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.prose-sm {
  font-size: .875rem;
  line-height: 1.71429;
}

.prose-sm :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .888889em;
  margin-bottom: .888889em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.33333em;
  margin-bottom: 1.33333em;
  padding-inline-start: 1.11111em;
}

.prose-sm :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: .8em;
  font-size: 2.14286em;
  line-height: 1.2;
}

.prose-sm :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.6em;
  margin-bottom: .8em;
  font-size: 1.42857em;
  line-height: 1.4;
}

.prose-sm :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.55556em;
  margin-bottom: .444444em;
  font-size: 1.28571em;
  line-height: 1.55556;
}

.prose-sm :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.42857em;
  margin-bottom: .571429em;
  line-height: 1.42857;
}

.prose-sm :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .142857em;
  padding-inline-end: .357143em;
  padding-bottom: .142857em;
  border-radius: .3125rem;
  padding-inline-start: .357143em;
  font-size: .857143em;
}

.prose-sm :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
}

.prose-sm :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .9em;
}

.prose-sm :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .888889em;
}

.prose-sm :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .666667em;
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  border-radius: .25rem;
  margin-top: 1.66667em;
  margin-bottom: 1.66667em;
  padding-inline-start: 1em;
  font-size: .857143em;
  line-height: 1.66667;
}

.prose-sm :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
  padding-inline-start: 1.57143em;
}

.prose-sm :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  margin-bottom: .285714em;
}

.prose-sm :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .428571em;
}

.prose-sm :where(.prose-sm > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(.prose-sm > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(.prose-sm > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.14286em;
}

.prose-sm :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .571429em;
  margin-bottom: .571429em;
}

.prose-sm :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
  margin-bottom: 1.14286em;
}

.prose-sm :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.14286em;
}

.prose-sm :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .285714em;
  padding-inline-start: 1.57143em;
}

.prose-sm :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2.85714em;
  margin-bottom: 2.85714em;
}

.prose-sm :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose-sm :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-size: .857143em;
  line-height: 1.5;
}

.prose-sm :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-sm :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-sm :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .666667em;
  padding-inline-end: 1em;
  padding-bottom: .666667em;
  padding-inline-start: 1em;
}

.prose-sm :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose-sm :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose-sm :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
}

.prose-sm :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose-sm :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .666667em;
  font-size: .857143em;
  line-height: 1.33333;
}

.prose-sm :where(.prose-sm > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose-sm :where(.prose-sm > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-1 {
  inset: .25rem;
}

.-inset-y-px {
  top: -1px;
  bottom: -1px;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-left-2 {
  left: -.5rem;
}

.-left-8 {
  left: -2rem;
}

.-left-\[10000px\] {
  left: -10000px;
}

.-right-0\.5 {
  right: -.125rem;
}

.-right-1 {
  right: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-3 {
  right: -.75rem;
}

.-right-8 {
  right: -2rem;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-9 {
  top: -2.25rem;
}

.-top-\[10000px\] {
  top: -10000px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-1\/4 {
  left: 25%;
}

.left-2 {
  left: .5rem;
}

.left-3\/4 {
  left: 75%;
}

.left-4 {
  left: 1rem;
}

.left-\[max\(-7rem\,calc\(50\%-52rem\)\)\] {
  left: max(-7rem, 50% - 52rem);
}

.left-\[max\(35rem\,calc\(50\%\+4rem\)\)\] {
  left: max(35rem, 50% + 4rem);
}

.left-full {
  left: 100%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-1\/2 {
  right: 50%;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-44 {
  right: 11rem;
}

.right-5 {
  right: 1.25rem;
}

.right-56 {
  right: 14rem;
}

.right-\[510px\] {
  right: 510px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-5 {
  top: 1.25rem;
}

.top-\[17px\] {
  top: 17px;
}

.top-full {
  top: 100%;
}

.\!z-50 {
  z-index: 50 !important;
}

.-z-10 {
  z-index: -10;
}

.-z-20 {
  z-index: -20;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-60 {
  z-index: 60;
}

.z-70 {
  z-index: 70;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.m-2 {
  margin: .5rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-0\.5 {
  margin-top: -.125rem;
  margin-bottom: -.125rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.\!mb-0 {
  margin-bottom: 0 !important;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.\!mt-4 {
  margin-top: 1rem !important;
}

.\!mt-5 {
  margin-top: 1.25rem !important;
}

.\!mt-6 {
  margin-top: 1.5rem !important;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-ml-\[1px\], .-ml-px {
  margin-left: -1px;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[26px\] {
  margin-top: 26px;
}

.mt-\[2px\] {
  margin-top: 2px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-\[577\/310\] {
  aspect-ratio: 577 / 310;
}

.size-1 {
  width: .25rem;
  height: .25rem;
}

.size-16 {
  width: 4rem;
  height: 4rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-2\.5 {
  width: .625rem;
  height: .625rem;
}

.size-3\.5 {
  width: .875rem;
  height: .875rem;
}

.size-36 {
  width: 9rem;
  height: 9rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-40 {
  width: 10rem;
  height: 10rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-7 {
  width: 1.75rem;
  height: 1.75rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-\[5px\] {
  width: 5px;
  height: 5px;
}

.size-full {
  width: 100%;
  height: 100%;
}

.\!h-4 {
  height: 1rem !important;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-8 {
  height: 2rem !important;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[4px\] {
  height: 4px;
}

.h-\[5px\] {
  height: 5px;
}

.h-\[600px\] {
  height: 600px;
}

.h-\[6px\] {
  height: 6px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100svh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-\[200px\] {
  max-height: 200px;
}

.max-h-\[228px\] {
  max-height: 228px;
}

.max-h-\[80vh\] {
  max-height: 80vh;
}

.max-h-screen {
  max-height: 100svh;
}

.min-h-12, .min-h-\[3rem\] {
  min-height: 3rem;
}

.min-h-\[80px\] {
  min-height: 80px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100svh;
}

.\!w-4 {
  width: 1rem !important;
}

.\!w-5 {
  width: 1.25rem !important;
}

.\!w-8 {
  width: 2rem !important;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-20 {
  width: 5rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[1000px\] {
  width: 1000px;
}

.w-\[110px\] {
  width: 110px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[36\.0625rem\] {
  width: 36.0625rem;
}

.w-\[5px\] {
  width: 5px;
}

.w-\[6px\] {
  width: 6px;
}

.w-\[90\%\] {
  width: 90%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-\[10rem\] {
  min-width: 10rem;
}

.min-w-\[2000px\] {
  min-width: 2000px;
}

.min-w-\[280px\] {
  min-width: 280px;
}

.min-w-\[400px\] {
  min-width: 400px;
}

.min-w-full {
  min-width: 100%;
}

.min-w-max {
  min-width: max-content;
}

.min-w-min {
  min-width: min-content;
}

.max-w-0 {
  max-width: 0;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-36 {
  max-width: 9rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[2rem\] {
  max-width: 2rem;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[440px\] {
  max-width: 440px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-none {
  max-width: none;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.border-collapse {
  border-collapse: collapse;
}

.\!-translate-x-full {
  --tw-translate-x: -100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-0\.5 {
  --tw-translate-y: -.125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-4 {
  --tw-translate-y: -1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-px {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[calc\(-100\%_\+_13px\)\] {
  --tw-translate-x: calc(-100% + 13px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0\.5 {
  --tw-translate-y: .125rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[3px\] {
  --tw-translate-y: 3px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[8px\] {
  --tw-translate-y: 8px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[calc\(-100\%_-_8px\)\] {
  --tw-translate-y: calc(-100% - 8px);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-scale-y-100 {
  --tw-scale-y: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes draw-line {
  0% {
    stroke-dashoffset: 10000px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.animate-\[draw-line_5s_forwards\] {
  animation: 5s forwards draw-line;
}

.animate-none {
  animation: none;
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.snap-mandatory {
  --tw-scroll-snap-strictness: mandatory;
}

.scroll-py-2 {
  scroll-padding-top: .5rem;
  scroll-padding-bottom: .5rem;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-6 {
  column-gap: 1.5rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.125rem * var(--tw-space-x-reverse));
  margin-left: calc(.125rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.375rem * var(--tw-space-x-reverse));
  margin-left: calc(.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-\[2px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2px * var(--tw-space-y-reverse));
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-neutral-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-divide-opacity));
}

.divide-slate-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity));
}

.divide-stone-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-divide-opacity));
}

.divide-tremor-border > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-clip {
  overflow-x: clip;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-words {
  overflow-wrap: break-word;
}

.\!rounded-none {
  border-radius: 0 !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[1px\] {
  border-radius: 1px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-tremor-default {
  border-radius: .5rem;
}

.rounded-tremor-full {
  border-radius: 9999px;
}

.rounded-tremor-small {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-tremor-default {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-l-tremor-default {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-l-tremor-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-tremor-small {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-r-tremor-default {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-r-tremor-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-r-tremor-small {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t-tremor-default {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-t-\[1px\] {
  border-top-width: 1px;
}

.border-dashed {
  border-style: dashed;
}

.\!border-none {
  border-style: none !important;
}

.border-none {
  border-style: none;
}

.\!border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-amber-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity));
}

.border-blue-950 {
  --tw-border-opacity: 1;
  border-color: rgb(23 37 84 / var(--tw-border-opacity));
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

.border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

.border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

.border-cyan-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

.border-dark-tremor-background {
  --tw-border-opacity: 1;
  border-color: rgb(29 37 51 / var(--tw-border-opacity));
}

.border-dark-tremor-border {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-dark-tremor-brand {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.border-dark-tremor-brand-emphasis {
  --tw-border-opacity: 1;
  border-color: rgb(168 128 247 / var(--tw-border-opacity));
}

.border-dark-tremor-brand-inverted {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.border-dark-tremor-brand-subtle {
  --tw-border-opacity: 1;
  border-color: rgb(58 10 157 / var(--tw-border-opacity));
}

.border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-emerald-200 {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-emerald-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-emerald-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-emerald-900 {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-emerald-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-fuchsia-100 {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

.border-fuchsia-200 {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

.border-fuchsia-300 {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

.border-fuchsia-400 {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

.border-fuchsia-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

.border-fuchsia-500 {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

.border-fuchsia-600 {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

.border-fuchsia-700 {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

.border-fuchsia-800 {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

.border-fuchsia-900 {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

.border-fuchsia-950 {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-gray-700\/50 {
  border-color: #37415180;
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-gray-950 {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 244 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity));
}

.border-green-950 {
  --tw-border-opacity: 1;
  border-color: rgb(5 46 22 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

.border-indigo-950 {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

.border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-lime-950 {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.border-neutral-950 {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 237 213 / var(--tw-border-opacity));
}

.border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

.border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 247 237 / var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity));
}

.border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgb(154 52 18 / var(--tw-border-opacity));
}

.border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgb(124 45 18 / var(--tw-border-opacity));
}

.border-orange-950 {
  --tw-border-opacity: 1;
  border-color: rgb(67 20 7 / var(--tw-border-opacity));
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-pink-950 {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-primary, .border-primary-500 {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.border-primary-600 {
  --tw-border-opacity: 1;
  border-color: rgb(104 37 242 / var(--tw-border-opacity));
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-purple-950 {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 226 226 / var(--tw-border-opacity));
}

.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgb(254 242 242 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-red-700\/50 {
  border-color: #b91c1c80;
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}

.border-red-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 10 10 / var(--tw-border-opacity));
}

.border-rose-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.border-rose-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

.border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.border-rose-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

.border-rose-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.border-rose-600 {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

.border-rose-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

.border-rose-800 {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

.border-rose-900 {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

.border-rose-950 {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

.border-secondary-200 {
  --tw-border-opacity: 1;
  border-color: rgb(246 217 255 / var(--tw-border-opacity));
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

.border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

.border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

.border-slate-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

.border-stone-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

.border-stone-200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.border-stone-400 {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.border-stone-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

.border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

.border-stone-600 {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.border-stone-700 {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.border-stone-900 {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

.border-stone-950 {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

.border-teal-100 {
  --tw-border-opacity: 1;
  border-color: rgb(204 251 241 / var(--tw-border-opacity));
}

.border-teal-200 {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity));
}

.border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

.border-teal-400 {
  --tw-border-opacity: 1;
  border-color: rgb(45 212 191 / var(--tw-border-opacity));
}

.border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

.border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

.border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

.border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}

.border-teal-900 {
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

.border-teal-950 {
  --tw-border-opacity: 1;
  border-color: rgb(4 47 46 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-tremor-background {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-tremor-border {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-tremor-brand {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.border-tremor-brand-emphasis {
  --tw-border-opacity: 1;
  border-color: rgb(78 13 210 / var(--tw-border-opacity));
}

.border-tremor-brand-inverted {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-tremor-brand-subtle {
  --tw-border-opacity: 1;
  border-color: rgb(168 128 247 / var(--tw-border-opacity));
}

.border-violet-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.border-violet-200 {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

.border-violet-300 {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.border-violet-400 {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.border-violet-50 {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.border-violet-700 {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

.border-violet-800 {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

.border-violet-900 {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.border-violet-950 {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 252 239 / var(--tw-border-opacity));
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(255 246 198 / var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(255 239 158 / var(--tw-border-opacity));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(255 232 117 / var(--tw-border-opacity));
}

.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 225 76 / var(--tw-border-opacity));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 20 / var(--tw-border-opacity));
}

.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgb(219 182 0 / var(--tw-border-opacity));
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgb(163 135 0 / var(--tw-border-opacity));
}

.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgb(46 38 0 / var(--tw-border-opacity));
}

.border-yellow-950 {
  --tw-border-opacity: 1;
  border-color: rgb(66 32 6 / var(--tw-border-opacity));
}

.border-zinc-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-zinc-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.border-zinc-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-zinc-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.border-zinc-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.border-zinc-700 {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

.border-zinc-800 {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.border-zinc-900 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

.border-zinc-950 {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

.border-b-stone-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.border-t-stone-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.\!bg-primary-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(104 37 242 / var(--tw-bg-opacity)) !important;
}

.\!bg-slate-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#ea80ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 128 255 / var(--tw-bg-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/20 {
  background-color: #0003;
}

.bg-black\/30 {
  background-color: #0000004d;
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-black\/70 {
  background-color: #000000b3;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-200\/40 {
  background-color: #bfdbfe66;
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.bg-cyan-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.bg-dark-tremor-background {
  --tw-bg-opacity: 1;
  background-color: rgb(29 37 51 / var(--tw-bg-opacity));
}

.bg-dark-tremor-background-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-dark-tremor-brand {
  --tw-bg-opacity: 1;
  background-color: rgb(141 90 245 / var(--tw-bg-opacity));
}

.bg-dark-tremor-brand-emphasis {
  --tw-bg-opacity: 1;
  background-color: rgb(168 128 247 / var(--tw-bg-opacity));
}

.bg-dark-tremor-brand-faint {
  --tw-bg-opacity: 1;
  background-color: rgb(11 18 41 / var(--tw-bg-opacity));
}

.bg-dark-tremor-brand-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(39 6 104 / var(--tw-bg-opacity));
}

.bg-dark-tremor-content-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.bg-fuchsia-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-900\/80 {
  background-color: #111827cc;
}

.bg-gray-900\/90 {
  background-color: #111827e6;
}

.bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.bg-green-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.bg-indigo-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.bg-neutral-500\/50 {
  background-color: #73737380;
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}

.bg-orange-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.bg-pink-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 244 254 / var(--tw-bg-opacity));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 205 252 / var(--tw-bg-opacity));
}

.bg-primary-300\/60 {
  background-color: #c2a7fa99;
}

.bg-primary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(141 90 245 / var(--tw-bg-opacity));
}

.bg-primary-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(104 37 242 / var(--tw-bg-opacity));
}

.bg-primary-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(78 13 210 / var(--tw-bg-opacity));
}

.bg-primary\/20 {
  background-color: #8d5af533;
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-100\/60 {
  background-color: #f3e8ff99;
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.bg-purple-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.bg-red-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 10 10 / var(--tw-bg-opacity));
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.bg-rose-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(234 128 255 / var(--tw-bg-opacity));
}

.bg-secondary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 245 255 / var(--tw-bg-opacity));
}

.bg-secondary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(246 217 255 / var(--tw-bg-opacity));
}

.bg-secondary-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 159 255 / var(--tw-bg-opacity));
}

.bg-secondary-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 128 255 / var(--tw-bg-opacity));
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-100\/80 {
  background-color: #f1f5f9cc;
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-slate-950\/30 {
  background-color: #0206174d;
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.bg-stone-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

.bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

.bg-teal-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-tremor-background {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-tremor-background-emphasis {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-tremor-background-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-tremor-background-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-tremor-border {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-tremor-brand {
  --tw-bg-opacity: 1;
  background-color: rgb(141 90 245 / var(--tw-bg-opacity));
}

.bg-tremor-brand-faint {
  --tw-bg-opacity: 1;
  background-color: rgb(247 244 254 / var(--tw-bg-opacity));
}

.bg-tremor-brand-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(221 205 252 / var(--tw-bg-opacity));
}

.bg-tremor-brand-muted\/50 {
  background-color: #ddcdfc80;
}

.bg-tremor-brand-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(168 128 247 / var(--tw-bg-opacity));
}

.bg-tremor-content-subtle {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-violet-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-white\/60 {
  background-color: #fff9;
}

.bg-white\/70 {
  background-color: #ffffffb3;
}

.bg-white\/80 {
  background-color: #fffc;
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 239 / var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 198 / var(--tw-bg-opacity));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 158 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 117 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 225 76 / var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 20 / var(--tw-bg-opacity));
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 182 0 / var(--tw-bg-opacity));
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 135 0 / var(--tw-bg-opacity));
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 38 0 / var(--tw-bg-opacity));
}

.bg-yellow-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity));
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-40 {
  --tw-bg-opacity: .4;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#ff80b5\] {
  --tw-gradient-from: #ff80b5 var(--tw-gradient-from-position);
  --tw-gradient-to: #ff80b500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[\#9089fc\] {
  --tw-gradient-to: #9089fc var(--tw-gradient-to-position);
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.\!fill-green-500 {
  fill: #22c55e !important;
}

.\!fill-red-500 {
  fill: #ef4444 !important;
}

.\!fill-white {
  fill: #fff !important;
}

.fill-amber-100 {
  fill: #fef3c7;
}

.fill-amber-200 {
  fill: #fde68a;
}

.fill-amber-300 {
  fill: #fcd34d;
}

.fill-amber-400 {
  fill: #fbbf24;
}

.fill-amber-50 {
  fill: #fffbeb;
}

.fill-amber-500 {
  fill: #f59e0b;
}

.fill-amber-600 {
  fill: #d97706;
}

.fill-amber-700 {
  fill: #b45309;
}

.fill-amber-800 {
  fill: #92400e;
}

.fill-amber-900 {
  fill: #78350f;
}

.fill-amber-950 {
  fill: #451a03;
}

.fill-blue-100 {
  fill: #dbeafe;
}

.fill-blue-200 {
  fill: #bfdbfe;
}

.fill-blue-300 {
  fill: #93c5fd;
}

.fill-blue-400 {
  fill: #60a5fa;
}

.fill-blue-50 {
  fill: #eff6ff;
}

.fill-blue-500 {
  fill: #3b82f6;
}

.fill-blue-600 {
  fill: #2563eb;
}

.fill-blue-700 {
  fill: #1d4ed8;
}

.fill-blue-800 {
  fill: #1e40af;
}

.fill-blue-900 {
  fill: #1e3a8a;
}

.fill-blue-950 {
  fill: #172554;
}

.fill-cyan-100 {
  fill: #cffafe;
}

.fill-cyan-200 {
  fill: #a5f3fc;
}

.fill-cyan-300 {
  fill: #67e8f9;
}

.fill-cyan-400 {
  fill: #22d3ee;
}

.fill-cyan-50 {
  fill: #ecfeff;
}

.fill-cyan-500 {
  fill: #06b6d4;
}

.fill-cyan-600 {
  fill: #0891b2;
}

.fill-cyan-700 {
  fill: #0e7490;
}

.fill-cyan-800 {
  fill: #155e75;
}

.fill-cyan-900 {
  fill: #164e63;
}

.fill-cyan-950 {
  fill: #083344;
}

.fill-dark-tremor-content {
  fill: #6b7280;
}

.fill-dark-tremor-content-emphasis {
  fill: #e5e7eb;
}

.fill-emerald-100 {
  fill: #d1fae5;
}

.fill-emerald-200 {
  fill: #a7f3d0;
}

.fill-emerald-300 {
  fill: #6ee7b7;
}

.fill-emerald-400 {
  fill: #34d399;
}

.fill-emerald-50 {
  fill: #ecfdf5;
}

.fill-emerald-500 {
  fill: #10b981;
}

.fill-emerald-600 {
  fill: #059669;
}

.fill-emerald-700 {
  fill: #047857;
}

.fill-emerald-800 {
  fill: #065f46;
}

.fill-emerald-900 {
  fill: #064e3b;
}

.fill-emerald-950 {
  fill: #022c22;
}

.fill-fuchsia-100 {
  fill: #fae8ff;
}

.fill-fuchsia-200 {
  fill: #f5d0fe;
}

.fill-fuchsia-300 {
  fill: #f0abfc;
}

.fill-fuchsia-400 {
  fill: #e879f9;
}

.fill-fuchsia-50 {
  fill: #fdf4ff;
}

.fill-fuchsia-500 {
  fill: #d946ef;
}

.fill-fuchsia-600 {
  fill: #c026d3;
}

.fill-fuchsia-700 {
  fill: #a21caf;
}

.fill-fuchsia-800 {
  fill: #86198f;
}

.fill-fuchsia-900 {
  fill: #701a75;
}

.fill-fuchsia-950 {
  fill: #4a044e;
}

.fill-gray-100 {
  fill: #f3f4f6;
}

.fill-gray-200 {
  fill: #e5e7eb;
}

.fill-gray-300 {
  fill: #d1d5db;
}

.fill-gray-400 {
  fill: #9ca3af;
}

.fill-gray-50 {
  fill: #f9fafb;
}

.fill-gray-500 {
  fill: #6b7280;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-gray-700 {
  fill: #374151;
}

.fill-gray-800 {
  fill: #1f2937;
}

.fill-gray-900 {
  fill: #111827;
}

.fill-gray-950 {
  fill: #030712;
}

.fill-green-100 {
  fill: #dcfce7;
}

.fill-green-200 {
  fill: #bbf7d0;
}

.fill-green-300 {
  fill: #86efac;
}

.fill-green-400 {
  fill: #4ade80;
}

.fill-green-50 {
  fill: #f0fdf4;
}

.fill-green-500 {
  fill: #22c55e;
}

.fill-green-600 {
  fill: #16a34a;
}

.fill-green-700 {
  fill: #15803d;
}

.fill-green-800 {
  fill: #166534;
}

.fill-green-900 {
  fill: #14532d;
}

.fill-green-950 {
  fill: #052e16;
}

.fill-indigo-100 {
  fill: #e0e7ff;
}

.fill-indigo-200 {
  fill: #c7d2fe;
}

.fill-indigo-300 {
  fill: #a5b4fc;
}

.fill-indigo-400 {
  fill: #818cf8;
}

.fill-indigo-50 {
  fill: #eef2ff;
}

.fill-indigo-500 {
  fill: #6366f1;
}

.fill-indigo-600 {
  fill: #4f46e5;
}

.fill-indigo-700 {
  fill: #4338ca;
}

.fill-indigo-800 {
  fill: #3730a3;
}

.fill-indigo-900 {
  fill: #312e81;
}

.fill-indigo-950 {
  fill: #1e1b4b;
}

.fill-lime-100 {
  fill: #ecfccb;
}

.fill-lime-200 {
  fill: #d9f99d;
}

.fill-lime-300 {
  fill: #bef264;
}

.fill-lime-400 {
  fill: #a3e635;
}

.fill-lime-50 {
  fill: #f7fee7;
}

.fill-lime-500 {
  fill: #84cc16;
}

.fill-lime-600 {
  fill: #65a30d;
}

.fill-lime-700 {
  fill: #4d7c0f;
}

.fill-lime-800 {
  fill: #3f6212;
}

.fill-lime-900 {
  fill: #365314;
}

.fill-lime-950 {
  fill: #1a2e05;
}

.fill-neutral-100 {
  fill: #f5f5f5;
}

.fill-neutral-200 {
  fill: #e5e5e5;
}

.fill-neutral-300 {
  fill: #d4d4d4;
}

.fill-neutral-400 {
  fill: #a3a3a3;
}

.fill-neutral-50 {
  fill: #fafafa;
}

.fill-neutral-500 {
  fill: #737373;
}

.fill-neutral-600 {
  fill: #525252;
}

.fill-neutral-700 {
  fill: #404040;
}

.fill-neutral-800 {
  fill: #262626;
}

.fill-neutral-900 {
  fill: #171717;
}

.fill-neutral-950 {
  fill: #0a0a0a;
}

.fill-none {
  fill: none;
}

.fill-orange-100 {
  fill: #ffedd5;
}

.fill-orange-200 {
  fill: #fed7aa;
}

.fill-orange-300 {
  fill: #fdba74;
}

.fill-orange-400 {
  fill: #fb923c;
}

.fill-orange-50 {
  fill: #fff7ed;
}

.fill-orange-500 {
  fill: #f97316;
}

.fill-orange-600 {
  fill: #ea580c;
}

.fill-orange-700 {
  fill: #c2410c;
}

.fill-orange-800 {
  fill: #9a3412;
}

.fill-orange-900 {
  fill: #7c2d12;
}

.fill-orange-950 {
  fill: #431407;
}

.fill-pink-100 {
  fill: #fce7f3;
}

.fill-pink-200 {
  fill: #fbcfe8;
}

.fill-pink-300 {
  fill: #f9a8d4;
}

.fill-pink-400 {
  fill: #f472b6;
}

.fill-pink-50 {
  fill: #fdf2f8;
}

.fill-pink-500 {
  fill: #ec4899;
}

.fill-pink-600 {
  fill: #db2777;
}

.fill-pink-700 {
  fill: #be185d;
}

.fill-pink-800 {
  fill: #9d174d;
}

.fill-pink-900 {
  fill: #831843;
}

.fill-pink-950 {
  fill: #500724;
}

.fill-purple-100 {
  fill: #f3e8ff;
}

.fill-purple-200 {
  fill: #e9d5ff;
}

.fill-purple-300 {
  fill: #d8b4fe;
}

.fill-purple-400 {
  fill: #c084fc;
}

.fill-purple-50 {
  fill: #faf5ff;
}

.fill-purple-500 {
  fill: #a855f7;
}

.fill-purple-600 {
  fill: #9333ea;
}

.fill-purple-700 {
  fill: #7e22ce;
}

.fill-purple-800 {
  fill: #6b21a8;
}

.fill-purple-900 {
  fill: #581c87;
}

.fill-purple-950 {
  fill: #3b0764;
}

.fill-red-100 {
  fill: #fee2e2;
}

.fill-red-200 {
  fill: #fecaca;
}

.fill-red-300 {
  fill: #fca5a5;
}

.fill-red-400 {
  fill: #f87171;
}

.fill-red-50 {
  fill: #fef2f2;
}

.fill-red-500 {
  fill: #ef4444;
}

.fill-red-600 {
  fill: #dc2626;
}

.fill-red-700 {
  fill: #b91c1c;
}

.fill-red-800 {
  fill: #991b1b;
}

.fill-red-900 {
  fill: #7f1d1d;
}

.fill-red-950 {
  fill: #450a0a;
}

.fill-rose-100 {
  fill: #ffe4e6;
}

.fill-rose-200 {
  fill: #fecdd3;
}

.fill-rose-300 {
  fill: #fda4af;
}

.fill-rose-400 {
  fill: #fb7185;
}

.fill-rose-50 {
  fill: #fff1f2;
}

.fill-rose-500 {
  fill: #f43f5e;
}

.fill-rose-600 {
  fill: #e11d48;
}

.fill-rose-700 {
  fill: #be123c;
}

.fill-rose-800 {
  fill: #9f1239;
}

.fill-rose-900 {
  fill: #881337;
}

.fill-rose-950 {
  fill: #4c0519;
}

.fill-sky-100 {
  fill: #e0f2fe;
}

.fill-sky-200 {
  fill: #bae6fd;
}

.fill-sky-300 {
  fill: #7dd3fc;
}

.fill-sky-400 {
  fill: #38bdf8;
}

.fill-sky-50 {
  fill: #f0f9ff;
}

.fill-sky-500 {
  fill: #0ea5e9;
}

.fill-sky-600 {
  fill: #0284c7;
}

.fill-sky-700 {
  fill: #0369a1;
}

.fill-sky-800 {
  fill: #075985;
}

.fill-sky-900 {
  fill: #0c4a6e;
}

.fill-sky-950 {
  fill: #082f49;
}

.fill-slate-100 {
  fill: #f1f5f9;
}

.fill-slate-200 {
  fill: #e2e8f0;
}

.fill-slate-300 {
  fill: #cbd5e1;
}

.fill-slate-400 {
  fill: #94a3b8;
}

.fill-slate-50 {
  fill: #f8fafc;
}

.fill-slate-500 {
  fill: #64748b;
}

.fill-slate-600 {
  fill: #475569;
}

.fill-slate-700 {
  fill: #334155;
}

.fill-slate-800 {
  fill: #1e293b;
}

.fill-slate-900 {
  fill: #0f172a;
}

.fill-slate-950 {
  fill: #020617;
}

.fill-stone-100 {
  fill: #f5f5f4;
}

.fill-stone-200 {
  fill: #e7e5e4;
}

.fill-stone-300 {
  fill: #d6d3d1;
}

.fill-stone-400 {
  fill: #a8a29e;
}

.fill-stone-50 {
  fill: #fafaf9;
}

.fill-stone-500 {
  fill: #78716c;
}

.fill-stone-600 {
  fill: #57534e;
}

.fill-stone-700 {
  fill: #44403c;
}

.fill-stone-800 {
  fill: #292524;
}

.fill-stone-900 {
  fill: #1c1917;
}

.fill-stone-950 {
  fill: #0c0a09;
}

.fill-teal-100 {
  fill: #ccfbf1;
}

.fill-teal-200 {
  fill: #99f6e4;
}

.fill-teal-300 {
  fill: #5eead4;
}

.fill-teal-400 {
  fill: #2dd4bf;
}

.fill-teal-50 {
  fill: #f0fdfa;
}

.fill-teal-500 {
  fill: #14b8a6;
}

.fill-teal-600 {
  fill: #0d9488;
}

.fill-teal-700 {
  fill: #0f766e;
}

.fill-teal-800 {
  fill: #115e59;
}

.fill-teal-900 {
  fill: #134e4a;
}

.fill-teal-950 {
  fill: #042f2e;
}

.fill-tremor-content {
  fill: #6b7280;
}

.fill-tremor-content-emphasis {
  fill: #374151;
}

.fill-violet-100 {
  fill: #ede9fe;
}

.fill-violet-200 {
  fill: #ddd6fe;
}

.fill-violet-300 {
  fill: #c4b5fd;
}

.fill-violet-400 {
  fill: #a78bfa;
}

.fill-violet-50 {
  fill: #f5f3ff;
}

.fill-violet-500 {
  fill: #8b5cf6;
}

.fill-violet-600 {
  fill: #7c3aed;
}

.fill-violet-700 {
  fill: #6d28d9;
}

.fill-violet-800 {
  fill: #5b21b6;
}

.fill-violet-900 {
  fill: #4c1d95;
}

.fill-violet-950 {
  fill: #2e1065;
}

.fill-white {
  fill: #fff;
}

.fill-yellow-100 {
  fill: #fffcef;
}

.fill-yellow-200 {
  fill: #fff6c6;
}

.fill-yellow-300 {
  fill: #ffef9e;
}

.fill-yellow-400 {
  fill: #ffe875;
}

.fill-yellow-50 {
  fill: #fff;
}

.fill-yellow-500 {
  fill: #ffe14c;
}

.fill-yellow-600 {
  fill: #ffd814;
}

.fill-yellow-700 {
  fill: #dbb600;
}

.fill-yellow-800 {
  fill: #a38700;
}

.fill-yellow-900 {
  fill: #2e2600;
}

.fill-yellow-950 {
  fill: #422006;
}

.fill-zinc-100 {
  fill: #f4f4f5;
}

.fill-zinc-200 {
  fill: #e4e4e7;
}

.fill-zinc-300 {
  fill: #d4d4d8;
}

.fill-zinc-400 {
  fill: #a1a1aa;
}

.fill-zinc-50 {
  fill: #fafafa;
}

.fill-zinc-500 {
  fill: #71717a;
}

.fill-zinc-600 {
  fill: #52525b;
}

.fill-zinc-700 {
  fill: #3f3f46;
}

.fill-zinc-800 {
  fill: #27272a;
}

.fill-zinc-900 {
  fill: #18181b;
}

.fill-zinc-950 {
  fill: #09090b;
}

.stroke-amber-100 {
  stroke: #fef3c7;
}

.stroke-amber-200 {
  stroke: #fde68a;
}

.stroke-amber-300 {
  stroke: #fcd34d;
}

.stroke-amber-400 {
  stroke: #fbbf24;
}

.stroke-amber-50 {
  stroke: #fffbeb;
}

.stroke-amber-500 {
  stroke: #f59e0b;
}

.stroke-amber-600 {
  stroke: #d97706;
}

.stroke-amber-700 {
  stroke: #b45309;
}

.stroke-amber-800 {
  stroke: #92400e;
}

.stroke-amber-900 {
  stroke: #78350f;
}

.stroke-amber-950 {
  stroke: #451a03;
}

.stroke-blue-100 {
  stroke: #dbeafe;
}

.stroke-blue-200 {
  stroke: #bfdbfe;
}

.stroke-blue-300 {
  stroke: #93c5fd;
}

.stroke-blue-400 {
  stroke: #60a5fa;
}

.stroke-blue-50 {
  stroke: #eff6ff;
}

.stroke-blue-500 {
  stroke: #3b82f6;
}

.stroke-blue-600 {
  stroke: #2563eb;
}

.stroke-blue-700 {
  stroke: #1d4ed8;
}

.stroke-blue-800 {
  stroke: #1e40af;
}

.stroke-blue-900 {
  stroke: #1e3a8a;
}

.stroke-blue-950 {
  stroke: #172554;
}

.stroke-cyan-100 {
  stroke: #cffafe;
}

.stroke-cyan-200 {
  stroke: #a5f3fc;
}

.stroke-cyan-300 {
  stroke: #67e8f9;
}

.stroke-cyan-400 {
  stroke: #22d3ee;
}

.stroke-cyan-50 {
  stroke: #ecfeff;
}

.stroke-cyan-500 {
  stroke: #06b6d4;
}

.stroke-cyan-600 {
  stroke: #0891b2;
}

.stroke-cyan-700 {
  stroke: #0e7490;
}

.stroke-cyan-800 {
  stroke: #155e75;
}

.stroke-cyan-900 {
  stroke: #164e63;
}

.stroke-cyan-950 {
  stroke: #083344;
}

.stroke-dark-tremor-background {
  stroke: #1d2533;
}

.stroke-dark-tremor-border {
  stroke: #1f2937;
}

.stroke-emerald-100 {
  stroke: #d1fae5;
}

.stroke-emerald-200 {
  stroke: #a7f3d0;
}

.stroke-emerald-300 {
  stroke: #6ee7b7;
}

.stroke-emerald-400 {
  stroke: #34d399;
}

.stroke-emerald-50 {
  stroke: #ecfdf5;
}

.stroke-emerald-500 {
  stroke: #10b981;
}

.stroke-emerald-600 {
  stroke: #059669;
}

.stroke-emerald-700 {
  stroke: #047857;
}

.stroke-emerald-800 {
  stroke: #065f46;
}

.stroke-emerald-900 {
  stroke: #064e3b;
}

.stroke-emerald-950 {
  stroke: #022c22;
}

.stroke-fuchsia-100 {
  stroke: #fae8ff;
}

.stroke-fuchsia-200 {
  stroke: #f5d0fe;
}

.stroke-fuchsia-300 {
  stroke: #f0abfc;
}

.stroke-fuchsia-400 {
  stroke: #e879f9;
}

.stroke-fuchsia-50 {
  stroke: #fdf4ff;
}

.stroke-fuchsia-500 {
  stroke: #d946ef;
}

.stroke-fuchsia-600 {
  stroke: #c026d3;
}

.stroke-fuchsia-700 {
  stroke: #a21caf;
}

.stroke-fuchsia-800 {
  stroke: #86198f;
}

.stroke-fuchsia-900 {
  stroke: #701a75;
}

.stroke-fuchsia-950 {
  stroke: #4a044e;
}

.stroke-gray-100 {
  stroke: #f3f4f6;
}

.stroke-gray-200 {
  stroke: #e5e7eb;
}

.stroke-gray-300 {
  stroke: #d1d5db;
}

.stroke-gray-400 {
  stroke: #9ca3af;
}

.stroke-gray-50 {
  stroke: #f9fafb;
}

.stroke-gray-500 {
  stroke: #6b7280;
}

.stroke-gray-600 {
  stroke: #4b5563;
}

.stroke-gray-700 {
  stroke: #374151;
}

.stroke-gray-800 {
  stroke: #1f2937;
}

.stroke-gray-900 {
  stroke: #111827;
}

.stroke-gray-950 {
  stroke: #030712;
}

.stroke-green-100 {
  stroke: #dcfce7;
}

.stroke-green-200 {
  stroke: #bbf7d0;
}

.stroke-green-300 {
  stroke: #86efac;
}

.stroke-green-400 {
  stroke: #4ade80;
}

.stroke-green-50 {
  stroke: #f0fdf4;
}

.stroke-green-500 {
  stroke: #22c55e;
}

.stroke-green-600 {
  stroke: #16a34a;
}

.stroke-green-700 {
  stroke: #15803d;
}

.stroke-green-800 {
  stroke: #166534;
}

.stroke-green-900 {
  stroke: #14532d;
}

.stroke-green-950 {
  stroke: #052e16;
}

.stroke-indigo-100 {
  stroke: #e0e7ff;
}

.stroke-indigo-200 {
  stroke: #c7d2fe;
}

.stroke-indigo-300 {
  stroke: #a5b4fc;
}

.stroke-indigo-400 {
  stroke: #818cf8;
}

.stroke-indigo-50 {
  stroke: #eef2ff;
}

.stroke-indigo-500 {
  stroke: #6366f1;
}

.stroke-indigo-600 {
  stroke: #4f46e5;
}

.stroke-indigo-700 {
  stroke: #4338ca;
}

.stroke-indigo-800 {
  stroke: #3730a3;
}

.stroke-indigo-900 {
  stroke: #312e81;
}

.stroke-indigo-950 {
  stroke: #1e1b4b;
}

.stroke-lime-100 {
  stroke: #ecfccb;
}

.stroke-lime-200 {
  stroke: #d9f99d;
}

.stroke-lime-300 {
  stroke: #bef264;
}

.stroke-lime-400 {
  stroke: #a3e635;
}

.stroke-lime-50 {
  stroke: #f7fee7;
}

.stroke-lime-500 {
  stroke: #84cc16;
}

.stroke-lime-600 {
  stroke: #65a30d;
}

.stroke-lime-700 {
  stroke: #4d7c0f;
}

.stroke-lime-800 {
  stroke: #3f6212;
}

.stroke-lime-900 {
  stroke: #365314;
}

.stroke-lime-950 {
  stroke: #1a2e05;
}

.stroke-neutral-100 {
  stroke: #f5f5f5;
}

.stroke-neutral-200 {
  stroke: #e5e5e5;
}

.stroke-neutral-300 {
  stroke: #d4d4d4;
}

.stroke-neutral-400 {
  stroke: #a3a3a3;
}

.stroke-neutral-50 {
  stroke: #fafafa;
}

.stroke-neutral-500 {
  stroke: #737373;
}

.stroke-neutral-600 {
  stroke: #525252;
}

.stroke-neutral-700 {
  stroke: #404040;
}

.stroke-neutral-800 {
  stroke: #262626;
}

.stroke-neutral-900 {
  stroke: #171717;
}

.stroke-neutral-950 {
  stroke: #0a0a0a;
}

.stroke-orange-100 {
  stroke: #ffedd5;
}

.stroke-orange-200 {
  stroke: #fed7aa;
}

.stroke-orange-300 {
  stroke: #fdba74;
}

.stroke-orange-400 {
  stroke: #fb923c;
}

.stroke-orange-50 {
  stroke: #fff7ed;
}

.stroke-orange-500 {
  stroke: #f97316;
}

.stroke-orange-600 {
  stroke: #ea580c;
}

.stroke-orange-700 {
  stroke: #c2410c;
}

.stroke-orange-800 {
  stroke: #9a3412;
}

.stroke-orange-900 {
  stroke: #7c2d12;
}

.stroke-orange-950 {
  stroke: #431407;
}

.stroke-pink-100 {
  stroke: #fce7f3;
}

.stroke-pink-200 {
  stroke: #fbcfe8;
}

.stroke-pink-300 {
  stroke: #f9a8d4;
}

.stroke-pink-400 {
  stroke: #f472b6;
}

.stroke-pink-50 {
  stroke: #fdf2f8;
}

.stroke-pink-500 {
  stroke: #ec4899;
}

.stroke-pink-600 {
  stroke: #db2777;
}

.stroke-pink-700 {
  stroke: #be185d;
}

.stroke-pink-800 {
  stroke: #9d174d;
}

.stroke-pink-900 {
  stroke: #831843;
}

.stroke-pink-950 {
  stroke: #500724;
}

.stroke-purple-100 {
  stroke: #f3e8ff;
}

.stroke-purple-200 {
  stroke: #e9d5ff;
}

.stroke-purple-300 {
  stroke: #d8b4fe;
}

.stroke-purple-400 {
  stroke: #c084fc;
}

.stroke-purple-50 {
  stroke: #faf5ff;
}

.stroke-purple-500 {
  stroke: #a855f7;
}

.stroke-purple-600 {
  stroke: #9333ea;
}

.stroke-purple-700 {
  stroke: #7e22ce;
}

.stroke-purple-800 {
  stroke: #6b21a8;
}

.stroke-purple-900 {
  stroke: #581c87;
}

.stroke-purple-950 {
  stroke: #3b0764;
}

.stroke-red-100 {
  stroke: #fee2e2;
}

.stroke-red-200 {
  stroke: #fecaca;
}

.stroke-red-300 {
  stroke: #fca5a5;
}

.stroke-red-400 {
  stroke: #f87171;
}

.stroke-red-50 {
  stroke: #fef2f2;
}

.stroke-red-500 {
  stroke: #ef4444;
}

.stroke-red-600 {
  stroke: #dc2626;
}

.stroke-red-700 {
  stroke: #b91c1c;
}

.stroke-red-800 {
  stroke: #991b1b;
}

.stroke-red-900 {
  stroke: #7f1d1d;
}

.stroke-red-950 {
  stroke: #450a0a;
}

.stroke-rose-100 {
  stroke: #ffe4e6;
}

.stroke-rose-200 {
  stroke: #fecdd3;
}

.stroke-rose-300 {
  stroke: #fda4af;
}

.stroke-rose-400 {
  stroke: #fb7185;
}

.stroke-rose-50 {
  stroke: #fff1f2;
}

.stroke-rose-500 {
  stroke: #f43f5e;
}

.stroke-rose-600 {
  stroke: #e11d48;
}

.stroke-rose-700 {
  stroke: #be123c;
}

.stroke-rose-800 {
  stroke: #9f1239;
}

.stroke-rose-900 {
  stroke: #881337;
}

.stroke-rose-950 {
  stroke: #4c0519;
}

.stroke-sky-100 {
  stroke: #e0f2fe;
}

.stroke-sky-200 {
  stroke: #bae6fd;
}

.stroke-sky-300 {
  stroke: #7dd3fc;
}

.stroke-sky-400 {
  stroke: #38bdf8;
}

.stroke-sky-50 {
  stroke: #f0f9ff;
}

.stroke-sky-500 {
  stroke: #0ea5e9;
}

.stroke-sky-600 {
  stroke: #0284c7;
}

.stroke-sky-700 {
  stroke: #0369a1;
}

.stroke-sky-800 {
  stroke: #075985;
}

.stroke-sky-900 {
  stroke: #0c4a6e;
}

.stroke-sky-950 {
  stroke: #082f49;
}

.stroke-slate-100 {
  stroke: #f1f5f9;
}

.stroke-slate-200 {
  stroke: #e2e8f0;
}

.stroke-slate-300 {
  stroke: #cbd5e1;
}

.stroke-slate-400 {
  stroke: #94a3b8;
}

.stroke-slate-50 {
  stroke: #f8fafc;
}

.stroke-slate-500 {
  stroke: #64748b;
}

.stroke-slate-600 {
  stroke: #475569;
}

.stroke-slate-700 {
  stroke: #334155;
}

.stroke-slate-800 {
  stroke: #1e293b;
}

.stroke-slate-900 {
  stroke: #0f172a;
}

.stroke-slate-950 {
  stroke: #020617;
}

.stroke-stone-100 {
  stroke: #f5f5f4;
}

.stroke-stone-200 {
  stroke: #e7e5e4;
}

.stroke-stone-300 {
  stroke: #d6d3d1;
}

.stroke-stone-400 {
  stroke: #a8a29e;
}

.stroke-stone-50 {
  stroke: #fafaf9;
}

.stroke-stone-500 {
  stroke: #78716c;
}

.stroke-stone-600 {
  stroke: #57534e;
}

.stroke-stone-700 {
  stroke: #44403c;
}

.stroke-stone-800 {
  stroke: #292524;
}

.stroke-stone-900 {
  stroke: #1c1917;
}

.stroke-stone-950 {
  stroke: #0c0a09;
}

.stroke-teal-100 {
  stroke: #ccfbf1;
}

.stroke-teal-200 {
  stroke: #99f6e4;
}

.stroke-teal-300 {
  stroke: #5eead4;
}

.stroke-teal-400 {
  stroke: #2dd4bf;
}

.stroke-teal-50 {
  stroke: #f0fdfa;
}

.stroke-teal-500 {
  stroke: #14b8a6;
}

.stroke-teal-600 {
  stroke: #0d9488;
}

.stroke-teal-700 {
  stroke: #0f766e;
}

.stroke-teal-800 {
  stroke: #115e59;
}

.stroke-teal-900 {
  stroke: #134e4a;
}

.stroke-teal-950 {
  stroke: #042f2e;
}

.stroke-tremor-background {
  stroke: #fff;
}

.stroke-tremor-border {
  stroke: #e5e7eb;
}

.stroke-tremor-brand {
  stroke: #8d5af5;
}

.stroke-tremor-brand-muted\/50 {
  stroke: #ddcdfc80;
}

.stroke-violet-100 {
  stroke: #ede9fe;
}

.stroke-violet-200 {
  stroke: #ddd6fe;
}

.stroke-violet-300 {
  stroke: #c4b5fd;
}

.stroke-violet-400 {
  stroke: #a78bfa;
}

.stroke-violet-50 {
  stroke: #f5f3ff;
}

.stroke-violet-500 {
  stroke: #8b5cf6;
}

.stroke-violet-600 {
  stroke: #7c3aed;
}

.stroke-violet-700 {
  stroke: #6d28d9;
}

.stroke-violet-800 {
  stroke: #5b21b6;
}

.stroke-violet-900 {
  stroke: #4c1d95;
}

.stroke-violet-950 {
  stroke: #2e1065;
}

.stroke-yellow-100 {
  stroke: #fffcef;
}

.stroke-yellow-200 {
  stroke: #fff6c6;
}

.stroke-yellow-300 {
  stroke: #ffef9e;
}

.stroke-yellow-400 {
  stroke: #ffe875;
}

.stroke-yellow-50 {
  stroke: #fff;
}

.stroke-yellow-500 {
  stroke: #ffe14c;
}

.stroke-yellow-600 {
  stroke: #ffd814;
}

.stroke-yellow-700 {
  stroke: #dbb600;
}

.stroke-yellow-800 {
  stroke: #a38700;
}

.stroke-yellow-900 {
  stroke: #2e2600;
}

.stroke-yellow-950 {
  stroke: #422006;
}

.stroke-zinc-100 {
  stroke: #f4f4f5;
}

.stroke-zinc-200 {
  stroke: #e4e4e7;
}

.stroke-zinc-300 {
  stroke: #d4d4d8;
}

.stroke-zinc-400 {
  stroke: #a1a1aa;
}

.stroke-zinc-50 {
  stroke: #fafafa;
}

.stroke-zinc-500 {
  stroke: #71717a;
}

.stroke-zinc-600 {
  stroke: #52525b;
}

.stroke-zinc-700 {
  stroke: #3f3f46;
}

.stroke-zinc-800 {
  stroke: #27272a;
}

.stroke-zinc-900 {
  stroke: #18181b;
}

.stroke-zinc-950 {
  stroke: #09090b;
}

.stroke-1 {
  stroke-width: 1px;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[1px\], .p-px {
  padding: 1px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-\[0\.2em\] {
  padding-left: .2em;
  padding-right: .2em;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.indent-2 {
  text-indent: .5rem;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-handwriting {
  font-family: Dancing Script;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.\!text-tremor-label {
  font-size: .75rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-\[0\.625rem\] {
  font-size: .625rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm, .text-tremor-default {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-tremor-label {
  font-size: .75rem;
}

.text-tremor-metric {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-tremor-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[48px\] {
  line-height: 48px;
}

.leading-none {
  line-height: 1;
}

.tracking-widest {
  letter-spacing: .1em;
}

.\!text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.\!text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}

.\!text-tremor-content-subtle {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.text-\[\#d1d5db\]\/15 {
  color: #d1d5db26;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/70 {
  color: #000000b3;
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-blue-950 {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.text-dark-tremor-brand {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.text-dark-tremor-brand-emphasis {
  --tw-text-opacity: 1;
  color: rgb(168 128 247 / var(--tw-text-opacity));
}

.text-dark-tremor-brand-inverted {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-dark-tremor-content {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-dark-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-dark-tremor-content-subtle {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.text-fuchsia-950 {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-green-950 {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity));
}

.text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity));
}

.text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-50 {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.text-orange-950 {
  --tw-text-opacity: 1;
  color: rgb(67 20 7 / var(--tw-text-opacity));
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.text-pink-950 {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.text-primary-200 {
  --tw-text-opacity: 1;
  color: rgb(221 205 252 / var(--tw-text-opacity));
}

.text-primary-300 {
  --tw-text-opacity: 1;
  color: rgb(194 167 250 / var(--tw-text-opacity));
}

.text-primary-400 {
  --tw-text-opacity: 1;
  color: rgb(168 128 247 / var(--tw-text-opacity));
}

.text-primary-500 {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(104 37 242 / var(--tw-text-opacity));
}

.text-primary-700 {
  --tw-text-opacity: 1;
  color: rgb(78 13 210 / var(--tw-text-opacity));
}

.text-primary-900 {
  --tw-text-opacity: 1;
  color: rgb(39 6 104 / var(--tw-text-opacity));
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.text-purple-950 {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-50 {
  --tw-text-opacity: 1;
  color: rgb(254 242 242 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.text-red-950 {
  --tw-text-opacity: 1;
  color: rgb(69 10 10 / var(--tw-text-opacity));
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.text-rose-300 {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-rose-50 {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.text-rose-950 {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-slate-950 {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.text-stone-400 {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.text-stone-950 {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

.text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

.text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

.text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.text-teal-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.text-teal-950 {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-tremor-brand {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.text-tremor-brand-emphasis {
  --tw-text-opacity: 1;
  color: rgb(78 13 210 / var(--tw-text-opacity));
}

.text-tremor-brand-inverted {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-tremor-content {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-tremor-content-strong {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-tremor-content-subtle {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.text-violet-300 {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.text-violet-50 {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.text-violet-950 {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(255 252 239 / var(--tw-text-opacity));
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(255 246 198 / var(--tw-text-opacity));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(255 239 158 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(255 232 117 / var(--tw-text-opacity));
}

.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(255 225 76 / var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(255 216 20 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(219 182 0 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(163 135 0 / var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(46 38 0 / var(--tw-text-opacity));
}

.text-yellow-950 {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity));
}

.text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.\!no-underline {
  text-decoration-line: none !important;
}

.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}

.accent-dark-tremor-brand, .accent-tremor-brand {
  accent-color: #8d5af5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_0_0_0_1px_rgba\(0\,0\,0\,0\.8\)\,_0_1px_2px_rgba\(0\,0\,0\,0\.15\)\] {
  --tw-shadow: inset 0 0 0 1px #000c, 0 1px 2px #00000026;
  --tw-shadow-colored: inset 0 0 0 1px var(--tw-shadow-color), 0 1px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[inset_3px_0_0_0_\#8D5AF5\] {
  --tw-shadow: inset 3px 0 0 0 #8d5af5;
  --tw-shadow-colored: inset 3px 0 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-dark-tremor-card {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-dark-tremor-input {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tremor-card {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tremor-dropdown {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-tremor-input {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.\!shadow-\[none\] {
  --tw-shadow-color: none !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.outline-gray-200 {
  outline-color: #e5e7eb;
}

.outline-tremor-brand {
  outline-color: #8d5af5;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-amber-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity));
}

.ring-amber-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity));
}

.ring-amber-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity));
}

.ring-amber-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity));
}

.ring-amber-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity));
}

.ring-amber-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity));
}

.ring-amber-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity));
}

.ring-amber-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity));
}

.ring-amber-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity));
}

.ring-amber-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity));
}

.ring-amber-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(69 26 3 / var(--tw-ring-opacity));
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-black\/20 {
  --tw-ring-color: #0003;
}

.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity));
}

.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
}

.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity));
}

.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity));
}

.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity));
}

.ring-blue-600\/20 {
  --tw-ring-color: #2563eb33;
}

.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity));
}

.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
}

.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity));
}

.ring-blue-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(23 37 84 / var(--tw-ring-opacity));
}

.ring-cyan-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity));
}

.ring-cyan-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity));
}

.ring-cyan-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
}

.ring-cyan-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity));
}

.ring-cyan-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity));
}

.ring-cyan-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}

.ring-cyan-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity));
}

.ring-cyan-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity));
}

.ring-cyan-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity));
}

.ring-cyan-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity));
}

.ring-cyan-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 51 68 / var(--tw-ring-opacity));
}

.ring-dark-tremor-ring {
  --tw-ring-color: #f9fafb1a;
}

.ring-emerald-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity));
}

.ring-emerald-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity));
}

.ring-emerald-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity));
}

.ring-emerald-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity));
}

.ring-emerald-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity));
}

.ring-emerald-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}

.ring-emerald-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity));
}

.ring-emerald-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity));
}

.ring-emerald-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity));
}

.ring-emerald-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity));
}

.ring-emerald-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 44 34 / var(--tw-ring-opacity));
}

.ring-fuchsia-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity));
}

.ring-fuchsia-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity));
}

.ring-fuchsia-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity));
}

.ring-fuchsia-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity));
}

.ring-fuchsia-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity));
}

.ring-fuchsia-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity));
}

.ring-fuchsia-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity));
}

.ring-fuchsia-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity));
}

.ring-fuchsia-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity));
}

.ring-fuchsia-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity));
}

.ring-fuchsia-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 4 78 / var(--tw-ring-opacity));
}

.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}

.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity));
}

.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity));
}

.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity));
}

.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity));
}

.ring-gray-600\/20 {
  --tw-ring-color: #4b556333;
}

.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity));
}

.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity));
}

.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}

.ring-gray-900\/5 {
  --tw-ring-color: #1118270d;
}

.ring-gray-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 7 18 / var(--tw-ring-opacity));
}

.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity));
}

.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity));
}

.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity));
}

.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity));
}

.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity));
}

.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity));
}

.ring-green-600\/20 {
  --tw-ring-color: #16a34a33;
}

.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity));
}

.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity));
}

.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity));
}

.ring-green-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(5 46 22 / var(--tw-ring-opacity));
}

.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity));
}

.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity));
}

.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity));
}

.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity));
}

.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity));
}

.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity));
}

.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity));
}

.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity));
}

.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity));
}

.ring-indigo-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 27 75 / var(--tw-ring-opacity));
}

.ring-lime-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity));
}

.ring-lime-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity));
}

.ring-lime-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity));
}

.ring-lime-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity));
}

.ring-lime-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity));
}

.ring-lime-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}

.ring-lime-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity));
}

.ring-lime-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity));
}

.ring-lime-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity));
}

.ring-lime-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity));
}

.ring-lime-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(26 46 5 / var(--tw-ring-opacity));
}

.ring-neutral-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity));
}

.ring-neutral-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity));
}

.ring-neutral-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity));
}

.ring-neutral-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity));
}

.ring-neutral-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}

.ring-neutral-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity));
}

.ring-neutral-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity));
}

.ring-neutral-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity));
}

.ring-neutral-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity));
}

.ring-neutral-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity));
}

.ring-neutral-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(10 10 10 / var(--tw-ring-opacity));
}

.ring-orange-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity));
}

.ring-orange-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity));
}

.ring-orange-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity));
}

.ring-orange-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity));
}

.ring-orange-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity));
}

.ring-orange-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
}

.ring-orange-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity));
}

.ring-orange-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity));
}

.ring-orange-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity));
}

.ring-orange-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity));
}

.ring-orange-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(67 20 7 / var(--tw-ring-opacity));
}

.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity));
}

.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity));
}

.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity));
}

.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity));
}

.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity));
}

.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}

.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity));
}

.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity));
}

.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(157 23 77 / var(--tw-ring-opacity));
}

.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity));
}

.ring-pink-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(80 7 36 / var(--tw-ring-opacity));
}

.ring-primary {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 90 245 / var(--tw-ring-opacity));
}

.ring-primary-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 128 247 / var(--tw-ring-opacity));
}

.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity));
}

.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity));
}

.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity));
}

.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity));
}

.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity));
}

.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}

.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity));
}

.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity));
}

.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity));
}

.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity));
}

.ring-purple-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 7 100 / var(--tw-ring-opacity));
}

.ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity));
}

.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}

.ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity));
}

.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity));
}

.ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity));
}

.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity));
}

.ring-red-600\/20 {
  --tw-ring-color: #dc262633;
}

.ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}

.ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity));
}

.ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity));
}

.ring-red-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(69 10 10 / var(--tw-ring-opacity));
}

.ring-rose-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity));
}

.ring-rose-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity));
}

.ring-rose-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity));
}

.ring-rose-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity));
}

.ring-rose-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity));
}

.ring-rose-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}

.ring-rose-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity));
}

.ring-rose-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity));
}

.ring-rose-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity));
}

.ring-rose-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity));
}

.ring-rose-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(76 5 25 / var(--tw-ring-opacity));
}

.ring-sky-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity));
}

.ring-sky-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity));
}

.ring-sky-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity));
}

.ring-sky-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity));
}

.ring-sky-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity));
}

.ring-sky-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}

.ring-sky-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity));
}

.ring-sky-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity));
}

.ring-sky-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity));
}

.ring-sky-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity));
}

.ring-sky-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(8 47 73 / var(--tw-ring-opacity));
}

.ring-slate-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity));
}

.ring-slate-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity));
}

.ring-slate-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity));
}

.ring-slate-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity));
}

.ring-slate-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity));
}

.ring-slate-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}

.ring-slate-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity));
}

.ring-slate-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}

.ring-slate-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity));
}

.ring-slate-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity));
}

.ring-slate-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(2 6 23 / var(--tw-ring-opacity));
}

.ring-stone-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity));
}

.ring-stone-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity));
}

.ring-stone-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity));
}

.ring-stone-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity));
}

.ring-stone-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity));
}

.ring-stone-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity));
}

.ring-stone-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity));
}

.ring-stone-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity));
}

.ring-stone-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity));
}

.ring-stone-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity));
}

.ring-stone-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(12 10 9 / var(--tw-ring-opacity));
}

.ring-teal-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity));
}

.ring-teal-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity));
}

.ring-teal-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity));
}

.ring-teal-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity));
}

.ring-teal-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity));
}

.ring-teal-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}

.ring-teal-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity));
}

.ring-teal-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity));
}

.ring-teal-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity));
}

.ring-teal-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity));
}

.ring-teal-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(4 47 46 / var(--tw-ring-opacity));
}

.ring-tremor-brand-inverted {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-tremor-brand-muted {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(221 205 252 / var(--tw-ring-opacity));
}

.ring-tremor-brand\/20 {
  --tw-ring-color: #8d5af533;
}

.ring-tremor-ring {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity));
}

.ring-violet-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity));
}

.ring-violet-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity));
}

.ring-violet-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity));
}

.ring-violet-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity));
}

.ring-violet-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity));
}

.ring-violet-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity));
}

.ring-violet-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity));
}

.ring-violet-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity));
}

.ring-violet-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity));
}

.ring-violet-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity));
}

.ring-violet-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(46 16 101 / var(--tw-ring-opacity));
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 252 239 / var(--tw-ring-opacity));
}

.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 246 198 / var(--tw-ring-opacity));
}

.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 239 158 / var(--tw-ring-opacity));
}

.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 232 117 / var(--tw-ring-opacity));
}

.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 225 76 / var(--tw-ring-opacity));
}

.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 216 20 / var(--tw-ring-opacity));
}

.ring-yellow-600\/40 {
  --tw-ring-color: #ffd81466;
}

.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(219 182 0 / var(--tw-ring-opacity));
}

.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(163 135 0 / var(--tw-ring-opacity));
}

.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(46 38 0 / var(--tw-ring-opacity));
}

.ring-yellow-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(66 32 6 / var(--tw-ring-opacity));
}

.ring-zinc-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity));
}

.ring-zinc-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity));
}

.ring-zinc-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity));
}

.ring-zinc-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity));
}

.ring-zinc-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity));
}

.ring-zinc-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity));
}

.ring-zinc-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity));
}

.ring-zinc-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity));
}

.ring-zinc-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity));
}

.ring-zinc-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity));
}

.ring-zinc-950 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(9 9 11 / var(--tw-ring-opacity));
}

.ring-opacity-20 {
  --tw-ring-opacity: .2;
}

.ring-opacity-40 {
  --tw-ring-opacity: .4;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-white {
  --tw-ring-offset-color: #fff;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-2xl {
  --tw-blur: blur(40px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.delay-1000 {
  transition-delay: 1s;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[--dot-bg\:white\] {
  --dot-bg: white;
}

.\[--dot-color\:\#6b7280\] {
  --dot-color: #6b7280;
}

.\[--dot-size\:1px\] {
  --dot-size: 1px;
}

.\[--dot-space\:3px\] {
  --dot-space: 3px;
}

.\[appearance\:textfield\] {
  appearance: textfield;
}

.\[scrollbar-width\:none\] {
  scrollbar-width: none;
}

:focus-visible {
  outline: initial;
}

html.dark, body.dark {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

@font-face {
  font-family: Eina;
  font-style: normal;
  font-weight: 300;
  src: local("Eina 02"), local(eina-02), url("eina-02-light.2fe51163.woff") format("truetype");
}

@font-face {
  font-family: Eina;
  font-style: normal;
  font-weight: 400;
  src: local("Eina 02"), local(eina-02), url("eina-02-regular.0d6e4ce4.woff") format("truetype");
}

@font-face {
  font-family: Eina;
  font-style: normal;
  font-weight: 600;
  src: local("Eina 02"), local(eina-02), url("eina-02-semibold.1b402771.woff") format("truetype");
}

@font-face {
  font-family: Eina;
  font-style: normal;
  font-weight: 700;
  src: local("Eina 02"), local(eina-02), url("eina-02-bold.ae454c8d.woff") format("truetype");
}

@font-face {
  font-family: Dancing Script;
  font-style: normal;
  font-weight: 400;
  src: url("Dancing-Script.ba85e838.ttf") format("truetype");
}

.text-upscope-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #ea80ff var(--tw-gradient-from-position);
  --tw-gradient-to: #8c57f6 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  color: #0000;
  background-clip: text;
}

.text-upscope-gradient-marquee {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #ea80ff var(--tw-gradient-from-position);
  --tw-gradient-to: #ea80ff var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #8c57f6 var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-via-position: 50%;
  color: #0000;
  background-clip: text;
  animation: 10s linear infinite move-upscope-gradient-background;
}

.underlined-links > a {
  pointer: cursor;
  text-decoration: underline;
}

.bg-dots {
  background: linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space)  - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space), linear-gradient(var(--dot-bg) calc(var(--dot-space)  - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space), var(--dot-color);
}

.shadow-multi {
  box-shadow: 0 0 0 1px #0000001a, 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
}

.clickable {
  cursor: pointer;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.clickable:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.clickable:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.normal-lists > ul, .normal-lists > ol {
  margin-left: 1.5em;
  list-style-position: outside;
}

.normal-lists > ul {
  list-style-type: disc;
}

.normal-lists > ol {
  list-style-type: decimal;
}

.normal-lists > ol > li, .normal-lists > ul > li {
  margin-bottom: 1em;
}

@keyframes float-animation {
  0% {
    transform: translateX(-5%);
  }

  50% {
    transform: translate(5%);
  }
}

@keyframes move-upscope-gradient-background {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 1000px;
  }
}

.animate-float {
  animation: 10s ease-in-out infinite alternate float-animation;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: .1;
  }
}

.animate-blink {
  animation: 1s ease-in-out infinite alternate blink-animation;
}

.placeholder\:text-sm::placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.placeholder\:text-red-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.placeholder\:text-tremor-content::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.placeholder\:text-tremor-content-subtle::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:right-0:before {
  content: var(--tw-content);
  right: 0;
}

.before\:top-1\/2:before {
  content: var(--tw-content);
  top: 50%;
}

.before\:size-0:before {
  content: var(--tw-content);
  width: 0;
  height: 0;
}

.before\:-translate-y-1\/2:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:translate-x-full:before {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:border-y-8:before {
  content: var(--tw-content);
  border-top-width: 8px;
  border-bottom-width: 8px;
}

.before\:border-l-8:before {
  content: var(--tw-content);
  border-left-width: 8px;
}

.before\:border-y-transparent:before {
  content: var(--tw-content);
  border-top-color: #0000;
  border-bottom-color: #0000;
}

.before\:border-l-primary\/20:before {
  content: var(--tw-content);
  border-left-color: #8d5af533;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:bottom-\[100\%\]:after {
  content: var(--tw-content);
  bottom: 100%;
}

.after\:left-1\/2:after {
  content: var(--tw-content);
  left: 50%;
}

.after\:left-4:after {
  content: var(--tw-content);
  left: 1rem;
}

.after\:right-2:after {
  content: var(--tw-content);
  right: .5rem;
}

.after\:top-\[100\%\]:after {
  content: var(--tw-content);
  top: 100%;
}

.after\:-translate-x-1\/2:after {
  content: var(--tw-content);
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:rotate-180:after {
  content: var(--tw-content);
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:border-8:after {
  content: var(--tw-content);
  border-width: 8px;
}

.after\:border-x-transparent:after {
  content: var(--tw-content);
  border-left-color: #0000;
  border-right-color: #0000;
}

.after\:border-b-transparent:after {
  content: var(--tw-content);
  border-bottom-color: #0000;
}

.after\:border-t-neutral-700:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-top-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.first\:rounded-l-\[4px\]:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.last\:rounded-r-\[4px\]:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.last\:pb-0:last-child {
  padding-bottom: 0;
}

.read-only\:select-none:read-only {
  -webkit-user-select: none;
  user-select: none;
}

.focus-within\:relative:focus-within {
  position: relative;
}

.focus-within\:border-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-primary:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 90 245 / var(--tw-ring-opacity));
}

.hover\:max-w-lg:hover {
  max-width: 32rem;
}

.hover\:scale-\[--max-scale\]:hover {
  --tw-scale-x: var(--max-scale);
  --tw-scale-y: var(--max-scale);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-b-2:hover {
  border-bottom-width: 2px;
}

.hover\:border-amber-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.hover\:border-amber-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.hover\:border-amber-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.hover\:border-amber-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.hover\:border-amber-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.hover\:border-amber-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.hover\:border-amber-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.hover\:border-amber-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.hover\:border-amber-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.hover\:border-amber-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.hover\:border-amber-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.hover\:border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.hover\:border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.hover\:border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

.hover\:border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity));
}

.hover\:border-blue-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(23 37 84 / var(--tw-border-opacity));
}

.hover\:border-cyan-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.hover\:border-cyan-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

.hover\:border-cyan-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.hover\:border-cyan-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

.hover\:border-cyan-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

.hover\:border-cyan-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.hover\:border-cyan-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.hover\:border-cyan-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.hover\:border-cyan-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

.hover\:border-cyan-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

.hover\:border-cyan-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

.hover\:border-emerald-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.hover\:border-emerald-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.hover\:border-emerald-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.hover\:border-emerald-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.hover\:border-emerald-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.hover\:border-emerald-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.hover\:border-emerald-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.hover\:border-emerald-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.hover\:border-emerald-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.hover\:border-emerald-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.hover\:border-emerald-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

.hover\:border-fuchsia-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.hover\:border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.hover\:border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.hover\:border-gray-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity));
}

.hover\:border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.hover\:border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 244 / var(--tw-border-opacity));
}

.hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.hover\:border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

.hover\:border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity));
}

.hover\:border-green-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(5 46 22 / var(--tw-border-opacity));
}

.hover\:border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.hover\:border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

.hover\:border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.hover\:border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.hover\:border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.hover\:border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

.hover\:border-indigo-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

.hover\:border-lime-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.hover\:border-lime-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.hover\:border-lime-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.hover\:border-lime-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.hover\:border-lime-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.hover\:border-lime-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.hover\:border-lime-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.hover\:border-lime-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.hover\:border-lime-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.hover\:border-lime-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.hover\:border-lime-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.hover\:border-neutral-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.hover\:border-neutral-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.hover\:border-neutral-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.hover\:border-neutral-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.hover\:border-neutral-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.hover\:border-neutral-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.hover\:border-neutral-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.hover\:border-neutral-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.hover\:border-neutral-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.hover\:border-neutral-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.hover\:border-neutral-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.hover\:border-orange-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 237 213 / var(--tw-border-opacity));
}

.hover\:border-orange-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

.hover\:border-orange-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.hover\:border-orange-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.hover\:border-orange-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 247 237 / var(--tw-border-opacity));
}

.hover\:border-orange-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.hover\:border-orange-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.hover\:border-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity));
}

.hover\:border-orange-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(154 52 18 / var(--tw-border-opacity));
}

.hover\:border-orange-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(124 45 18 / var(--tw-border-opacity));
}

.hover\:border-orange-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(67 20 7 / var(--tw-border-opacity));
}

.hover\:border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.hover\:border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.hover\:border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.hover\:border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.hover\:border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.hover\:border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.hover\:border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.hover\:border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.hover\:border-pink-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.hover\:border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.hover\:border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.hover\:border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.hover\:border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.hover\:border-purple-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.hover\:border-red-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 226 226 / var(--tw-border-opacity));
}

.hover\:border-red-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.hover\:border-red-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.hover\:border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.hover\:border-red-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 242 242 / var(--tw-border-opacity));
}

.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.hover\:border-red-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.hover\:border-red-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}

.hover\:border-red-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(69 10 10 / var(--tw-border-opacity));
}

.hover\:border-rose-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.hover\:border-rose-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

.hover\:border-rose-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.hover\:border-rose-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

.hover\:border-rose-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

.hover\:border-rose-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.hover\:border-rose-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

.hover\:border-rose-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

.hover\:border-rose-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

.hover\:border-rose-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

.hover\:border-rose-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

.hover\:border-sky-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

.hover\:border-sky-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.hover\:border-sky-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

.hover\:border-sky-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

.hover\:border-sky-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

.hover\:border-sky-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.hover\:border-sky-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.hover\:border-sky-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

.hover\:border-sky-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.hover\:border-sky-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.hover\:border-sky-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

.hover\:border-slate-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.hover\:border-slate-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.hover\:border-slate-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.hover\:border-slate-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.hover\:border-slate-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.hover\:border-slate-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.hover\:border-slate-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.hover\:border-slate-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.hover\:border-slate-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.hover\:border-slate-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

.hover\:border-slate-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

.hover\:border-stone-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

.hover\:border-stone-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.hover\:border-stone-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.hover\:border-stone-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.hover\:border-stone-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

.hover\:border-stone-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

.hover\:border-stone-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.hover\:border-stone-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.hover\:border-stone-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.hover\:border-stone-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

.hover\:border-stone-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

.hover\:border-teal-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(204 251 241 / var(--tw-border-opacity));
}

.hover\:border-teal-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity));
}

.hover\:border-teal-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

.hover\:border-teal-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(45 212 191 / var(--tw-border-opacity));
}

.hover\:border-teal-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

.hover\:border-teal-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

.hover\:border-teal-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

.hover\:border-teal-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

.hover\:border-teal-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}

.hover\:border-teal-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

.hover\:border-teal-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(4 47 46 / var(--tw-border-opacity));
}

.hover\:border-tremor-brand-emphasis:hover {
  --tw-border-opacity: 1;
  border-color: rgb(78 13 210 / var(--tw-border-opacity));
}

.hover\:border-tremor-content:hover {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.hover\:border-violet-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.hover\:border-violet-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

.hover\:border-violet-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.hover\:border-violet-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.hover\:border-violet-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

.hover\:border-violet-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.hover\:border-violet-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.hover\:border-violet-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

.hover\:border-violet-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

.hover\:border-violet-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.hover\:border-violet-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

.hover\:border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 252 239 / var(--tw-border-opacity));
}

.hover\:border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 246 198 / var(--tw-border-opacity));
}

.hover\:border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 239 158 / var(--tw-border-opacity));
}

.hover\:border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 232 117 / var(--tw-border-opacity));
}

.hover\:border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 225 76 / var(--tw-border-opacity));
}

.hover\:border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 20 / var(--tw-border-opacity));
}

.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(219 182 0 / var(--tw-border-opacity));
}

.hover\:border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(163 135 0 / var(--tw-border-opacity));
}

.hover\:border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(46 38 0 / var(--tw-border-opacity));
}

.hover\:border-yellow-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(66 32 6 / var(--tw-border-opacity));
}

.hover\:border-zinc-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.hover\:border-zinc-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.hover\:border-zinc-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.hover\:border-zinc-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.hover\:border-zinc-50:hover {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.hover\:border-zinc-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.hover\:border-zinc-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.hover\:border-zinc-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

.hover\:border-zinc-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.hover\:border-zinc-900:hover {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

.hover\:border-zinc-950:hover {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

.hover\:bg-amber-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.hover\:bg-amber-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.hover\:bg-amber-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.hover\:bg-amber-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.hover\:bg-amber-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.hover\:bg-amber-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.hover\:bg-amber-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.hover\:bg-amber-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.hover\:bg-amber-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.hover\:bg-blue-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.hover\:bg-cyan-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.hover\:bg-emerald-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.hover\:bg-fuchsia-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.hover\:bg-gray-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.hover\:bg-green-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.hover\:bg-indigo-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.hover\:bg-lime-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.hover\:bg-lime-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.hover\:bg-lime-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.hover\:bg-lime-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.hover\:bg-lime-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.hover\:bg-lime-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.hover\:bg-lime-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.hover\:bg-lime-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.hover\:bg-lime-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.hover\:bg-lime-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.hover\:bg-orange-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.hover\:bg-orange-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.hover\:bg-orange-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.hover\:bg-orange-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.hover\:bg-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.hover\:bg-orange-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

.hover\:bg-orange-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}

.hover\:bg-orange-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity));
}

.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.hover\:bg-pink-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.hover\:bg-primary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 244 254 / var(--tw-bg-opacity));
}

.hover\:bg-primary-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(221 205 252 / var(--tw-bg-opacity));
}

.hover\:bg-primary-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(104 37 242 / var(--tw-bg-opacity));
}

.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.hover\:bg-purple-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.hover\:bg-red-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(69 10 10 / var(--tw-bg-opacity));
}

.hover\:bg-rose-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.hover\:bg-rose-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.hover\:bg-rose-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.hover\:bg-rose-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.hover\:bg-rose-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.hover\:bg-rose-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.hover\:bg-rose-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.hover\:bg-rose-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.hover\:bg-rose-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.hover\:bg-rose-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.hover\:bg-rose-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.hover\:bg-sky-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.hover\:bg-sky-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.hover\:bg-sky-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.hover\:bg-sky-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.hover\:bg-sky-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.hover\:bg-sky-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.hover\:bg-sky-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.hover\:bg-sky-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.hover\:bg-sky-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.hover\:bg-sky-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:bg-slate-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.hover\:bg-slate-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.hover\:bg-slate-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.hover\:bg-slate-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.hover\:bg-slate-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.hover\:bg-slate-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.hover\:bg-stone-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.hover\:bg-stone-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.hover\:bg-stone-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.hover\:bg-stone-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.hover\:bg-stone-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.hover\:bg-stone-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.hover\:bg-stone-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.hover\:bg-stone-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.hover\:bg-stone-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.hover\:bg-stone-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.hover\:bg-stone-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.hover\:bg-teal-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.hover\:bg-teal-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.hover\:bg-teal-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

.hover\:bg-teal-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.hover\:bg-teal-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.hover\:bg-teal-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.hover\:bg-teal-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.hover\:bg-teal-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.hover\:bg-teal-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

.hover\:bg-teal-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

.hover\:bg-teal-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

.hover\:bg-tremor-background-muted:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-tremor-background-subtle:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-tremor-brand-emphasis:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(78 13 210 / var(--tw-bg-opacity));
}

.hover\:bg-tremor-brand-faint:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(247 244 254 / var(--tw-bg-opacity));
}

.hover\:bg-violet-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.hover\:bg-violet-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.hover\:bg-violet-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.hover\:bg-violet-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.hover\:bg-violet-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.hover\:bg-violet-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.hover\:bg-violet-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.hover\:bg-violet-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.hover\:bg-violet-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.hover\:bg-violet-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.hover\:bg-violet-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.hover\:bg-white\/95:hover {
  background-color: #fffffff2;
}

.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 239 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 198 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 158 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 117 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 225 76 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 20 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 182 0 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(163 135 0 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(46 38 0 / var(--tw-bg-opacity));
}

.hover\:bg-yellow-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.hover\:bg-zinc-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:text-amber-100:hover {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.hover\:text-amber-200:hover {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.hover\:text-amber-300:hover {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.hover\:text-amber-400:hover {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.hover\:text-amber-50:hover {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.hover\:text-amber-500:hover {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.hover\:text-amber-600:hover {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.hover\:text-amber-800:hover {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.hover\:text-amber-900:hover {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.hover\:text-amber-950:hover {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.hover\:text-blue-950:hover {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

.hover\:text-cyan-100:hover {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.hover\:text-cyan-200:hover {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.hover\:text-cyan-300:hover {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.hover\:text-cyan-400:hover {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.hover\:text-cyan-50:hover {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.hover\:text-cyan-500:hover {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.hover\:text-cyan-600:hover {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.hover\:text-cyan-800:hover {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.hover\:text-cyan-900:hover {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.hover\:text-cyan-950:hover {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.hover\:text-emerald-100:hover {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.hover\:text-emerald-200:hover {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.hover\:text-emerald-300:hover {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.hover\:text-emerald-400:hover {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.hover\:text-emerald-50:hover {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.hover\:text-emerald-500:hover {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.hover\:text-emerald-600:hover {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.hover\:text-emerald-700:hover {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.hover\:text-emerald-800:hover {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.hover\:text-emerald-900:hover {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.hover\:text-emerald-950:hover {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-100:hover {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-200:hover {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-300:hover {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-400:hover {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-50:hover {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-500:hover {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-600:hover {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-700:hover {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-800:hover {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-900:hover {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.hover\:text-fuchsia-950:hover {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:text-gray-950:hover {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.hover\:text-green-950:hover {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.hover\:text-indigo-950:hover {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.hover\:text-lime-100:hover {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.hover\:text-lime-200:hover {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.hover\:text-lime-300:hover {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.hover\:text-lime-400:hover {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.hover\:text-lime-50:hover {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.hover\:text-lime-500:hover {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.hover\:text-lime-600:hover {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.hover\:text-lime-800:hover {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.hover\:text-lime-900:hover {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.hover\:text-lime-950:hover {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.hover\:text-neutral-100:hover {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.hover\:text-neutral-200:hover {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.hover\:text-neutral-300:hover {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.hover\:text-neutral-400:hover {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.hover\:text-neutral-50:hover {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.hover\:text-neutral-500:hover {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.hover\:text-neutral-600:hover {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.hover\:text-neutral-700:hover {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.hover\:text-neutral-800:hover {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.hover\:text-neutral-900:hover {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.hover\:text-neutral-950:hover {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.hover\:text-orange-100:hover {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity));
}

.hover\:text-orange-200:hover {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity));
}

.hover\:text-orange-300:hover {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.hover\:text-orange-400:hover {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.hover\:text-orange-50:hover {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.hover\:text-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.hover\:text-orange-800:hover {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.hover\:text-orange-900:hover {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.hover\:text-orange-950:hover {
  --tw-text-opacity: 1;
  color: rgb(67 20 7 / var(--tw-text-opacity));
}

.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.hover\:text-pink-950:hover {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.hover\:text-primary-400:hover {
  --tw-text-opacity: 1;
  color: rgb(168 128 247 / var(--tw-text-opacity));
}

.hover\:text-primary-600:hover {
  --tw-text-opacity: 1;
  color: rgb(104 37 242 / var(--tw-text-opacity));
}

.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.hover\:text-purple-950:hover {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgb(254 242 242 / var(--tw-text-opacity));
}

.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.hover\:text-red-950:hover {
  --tw-text-opacity: 1;
  color: rgb(69 10 10 / var(--tw-text-opacity));
}

.hover\:text-rose-100:hover {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.hover\:text-rose-200:hover {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.hover\:text-rose-300:hover {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.hover\:text-rose-400:hover {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.hover\:text-rose-50:hover {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.hover\:text-rose-500:hover {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.hover\:text-rose-600:hover {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.hover\:text-rose-700:hover {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.hover\:text-rose-800:hover {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.hover\:text-rose-900:hover {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.hover\:text-rose-950:hover {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.hover\:text-sky-100:hover {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.hover\:text-sky-200:hover {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.hover\:text-sky-300:hover {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.hover\:text-sky-400:hover {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.hover\:text-sky-50:hover {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.hover\:text-sky-500:hover {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.hover\:text-sky-600:hover {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.hover\:text-sky-700:hover {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.hover\:text-sky-800:hover {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.hover\:text-sky-900:hover {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.hover\:text-sky-950:hover {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.hover\:text-slate-100:hover {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.hover\:text-slate-200:hover {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.hover\:text-slate-300:hover {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.hover\:text-slate-400:hover {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.hover\:text-slate-50:hover {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.hover\:text-slate-500:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.hover\:text-slate-600:hover {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.hover\:text-slate-700:hover {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.hover\:text-slate-800:hover {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.hover\:text-slate-900:hover {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.hover\:text-slate-950:hover {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.hover\:text-stone-100:hover {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.hover\:text-stone-200:hover {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.hover\:text-stone-300:hover {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.hover\:text-stone-400:hover {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.hover\:text-stone-50:hover {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.hover\:text-stone-500:hover {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.hover\:text-stone-600:hover {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.hover\:text-stone-700:hover {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.hover\:text-stone-800:hover {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.hover\:text-stone-900:hover {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.hover\:text-stone-950:hover {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.hover\:text-teal-100:hover {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

.hover\:text-teal-200:hover {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

.hover\:text-teal-300:hover {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

.hover\:text-teal-400:hover {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.hover\:text-teal-50:hover {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

.hover\:text-teal-500:hover {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.hover\:text-teal-600:hover {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.hover\:text-teal-800:hover {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.hover\:text-teal-900:hover {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.hover\:text-teal-950:hover {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

.hover\:text-tremor-brand-emphasis:hover {
  --tw-text-opacity: 1;
  color: rgb(78 13 210 / var(--tw-text-opacity));
}

.hover\:text-tremor-content:hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.hover\:text-tremor-content-emphasis:hover {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.hover\:text-violet-100:hover {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.hover\:text-violet-200:hover {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.hover\:text-violet-300:hover {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.hover\:text-violet-400:hover {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.hover\:text-violet-50:hover {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.hover\:text-violet-500:hover {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.hover\:text-violet-600:hover {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.hover\:text-violet-700:hover {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.hover\:text-violet-800:hover {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.hover\:text-violet-900:hover {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.hover\:text-violet-950:hover {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgb(255 252 239 / var(--tw-text-opacity));
}

.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgb(255 246 198 / var(--tw-text-opacity));
}

.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgb(255 239 158 / var(--tw-text-opacity));
}

.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgb(255 232 117 / var(--tw-text-opacity));
}

.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(255 225 76 / var(--tw-text-opacity));
}

.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgb(255 216 20 / var(--tw-text-opacity));
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgb(219 182 0 / var(--tw-text-opacity));
}

.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgb(163 135 0 / var(--tw-text-opacity));
}

.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgb(46 38 0 / var(--tw-text-opacity));
}

.hover\:text-yellow-950:hover {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity));
}

.hover\:text-zinc-100:hover {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.hover\:text-zinc-200:hover {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.hover\:text-zinc-300:hover {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.hover\:text-zinc-400:hover {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.hover\:text-zinc-50:hover {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.hover\:text-zinc-500:hover {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.hover\:text-zinc-600:hover {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.hover\:text-zinc-700:hover {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.hover\:text-zinc-800:hover {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.hover\:text-zinc-900:hover {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.hover\:text-zinc-950:hover {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-60:hover {
  opacity: .6;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.hover\:\!shadow-none:hover {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.focus\:border-tremor-brand-subtle:focus {
  --tw-border-opacity: 1;
  border-color: rgb(168 128 247 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 90 245 / var(--tw-ring-opacity));
}

.focus\:ring-primary-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(104 37 242 / var(--tw-ring-opacity));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.focus\:ring-tremor-brand-muted:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(221 205 252 / var(--tw-ring-opacity));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-gray-900:focus-visible {
  outline-color: #111827;
}

.focus-visible\:ring:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-primary-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 90 245 / var(--tw-ring-opacity));
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: .75;
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:scale-\[--min-scale\]:active {
  --tw-scale-x: var(--min-scale);
  --tw-scale-y: var(--min-scale);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.disabled\:pointer-events-none:disabled {
  pointer-events: none;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-gray-300:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.disabled\:bg-gray-50:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:hover\:bg-transparent:hover:disabled {
  background-color: #0000;
}

.group\/widget:hover .group-hover\/widget\:block, .group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:border-primary-600 {
  --tw-border-opacity: 1;
  border-color: rgb(104 37 242 / var(--tw-border-opacity));
}

.group\/table-row:hover .group-hover\/table-row\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-blue-400\/40 {
  background-color: #60a5fa66;
}

.group:hover .group-hover\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-tremor-brand-subtle\/30 {
  background-color: #a880f74d;
}

.group:hover .group-hover\:bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.group:hover .group-hover\:text-primary-600 {
  --tw-text-opacity: 1;
  color: rgb(104 37 242 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-tremor-content-emphasis {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.group\/video:hover .group-hover\/video\:opacity-100, .group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:active .group-active\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.has-error .group-\[\.has-error\]\:flex {
  display: flex;
}

.group.has-error .group-\[\.has-error\]\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.group.has-error .group-\[\.has-error\]\:pr-10 {
  padding-right: 2.5rem;
}

.group.has-error .group-\[\.has-error\]\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.group.has-error .group-\[\.has-error\]\:placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity));
}

.group.has-error .group-\[\.has-error\]\:placeholder\:text-red-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.group.has-error .group-\[\.has-error\]\:focus-within\:border-red-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.group.has-error .group-\[\.has-error\]\:focus-within\:outline-none:focus-within {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group.has-error .group-\[\.has-error\]\:focus-within\:ring-red-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.group.has-error .group-\[\.has-error\]\:focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.group.has-error .group-\[\.has-error\]\:focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group.has-error .group-\[\.has-error\]\:focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.aria-selected\:\!bg-tremor-background-subtle[aria-selected="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.aria-selected\:bg-tremor-background-emphasis[aria-selected="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.aria-selected\:\!text-tremor-content[aria-selected="true"] {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.aria-selected\:text-dark-tremor-brand-inverted[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.aria-selected\:text-tremor-brand-inverted[aria-selected="true"], .aria-selected\:text-tremor-content-inverted[aria-selected="true"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.data-\[closed\]\:max-h-0[data-closed] {
  max-height: 0;
}

.data-\[closed\]\:translate-y-1[data-closed] {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:opacity-0[data-closed], .data-\[colosed\]\:opacity-0[data-colosed] {
  opacity: 0;
}

.data-\[open\]\:opacity-100[data-open] {
  opacity: 1;
}

.data-\[enter\]\:duration-200[data-enter] {
  transition-duration: .2s;
}

.data-\[leave\]\:duration-150[data-leave] {
  transition-duration: .15s;
}

.data-\[enter\]\:ease-out[data-enter] {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.data-\[leave\]\:ease-in[data-leave] {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ui-open\:underline[data-headlessui-state~="open"], :where([data-headlessui-state~="open"]) .ui-open\:underline {
  text-decoration-line: underline;
}

.ui-selected\:border-b-2[data-headlessui-state~="selected"] {
  border-bottom-width: 2px;
}

.ui-selected\:border-amber-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.ui-selected\:border-amber-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity));
}

.ui-selected\:border-blue-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(23 37 84 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

.ui-selected\:border-cyan-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.ui-selected\:border-emerald-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

.ui-selected\:border-fuchsia-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.ui-selected\:border-gray-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.ui-selected\:border-green-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity));
}

.ui-selected\:border-green-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.ui-selected\:border-green-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.ui-selected\:border-green-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.ui-selected\:border-green-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 244 / var(--tw-border-opacity));
}

.ui-selected\:border-green-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.ui-selected\:border-green-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.ui-selected\:border-green-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.ui-selected\:border-green-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

.ui-selected\:border-green-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity));
}

.ui-selected\:border-green-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(5 46 22 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

.ui-selected\:border-indigo-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.ui-selected\:border-lime-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.ui-selected\:border-neutral-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 237 213 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 247 237 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(154 52 18 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(124 45 18 / var(--tw-border-opacity));
}

.ui-selected\:border-orange-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(67 20 7 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.ui-selected\:border-pink-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.ui-selected\:border-purple-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.ui-selected\:border-red-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 226 226 / var(--tw-border-opacity));
}

.ui-selected\:border-red-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.ui-selected\:border-red-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

.ui-selected\:border-red-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

.ui-selected\:border-red-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 242 242 / var(--tw-border-opacity));
}

.ui-selected\:border-red-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.ui-selected\:border-red-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.ui-selected\:border-red-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.ui-selected\:border-red-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.ui-selected\:border-red-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}

.ui-selected\:border-red-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(69 10 10 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

.ui-selected\:border-rose-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.ui-selected\:border-sky-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

.ui-selected\:border-slate-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

.ui-selected\:border-stone-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(204 251 241 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(45 212 191 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

.ui-selected\:border-teal-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(4 47 46 / var(--tw-border-opacity));
}

.ui-selected\:border-tremor-border[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.ui-selected\:border-tremor-brand[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.ui-selected\:border-violet-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 252 239 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 246 198 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 239 158 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 232 117 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 225 76 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 20 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(219 182 0 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(163 135 0 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(46 38 0 / var(--tw-border-opacity));
}

.ui-selected\:border-yellow-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(66 32 6 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-100[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-200[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-300[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-400[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-50[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-500[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-600[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-700[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-800[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-900[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

.ui-selected\:border-zinc-950[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

.ui-selected\:bg-amber-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.ui-selected\:bg-amber-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.ui-selected\:bg-blue-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.ui-selected\:bg-cyan-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.ui-selected\:bg-emerald-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.ui-selected\:bg-fuchsia-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.ui-selected\:bg-gray-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.ui-selected\:bg-green-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.ui-selected\:bg-indigo-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.ui-selected\:bg-lime-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.ui-selected\:bg-neutral-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}

.ui-selected\:bg-orange-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.ui-selected\:bg-pink-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.ui-selected\:bg-purple-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.ui-selected\:bg-red-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(69 10 10 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.ui-selected\:bg-rose-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.ui-selected\:bg-sky-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.ui-selected\:bg-slate-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.ui-selected\:bg-stone-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

.ui-selected\:bg-teal-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

.ui-selected\:bg-tremor-background[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-tremor-background-muted[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.ui-selected\:bg-violet-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 239 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 198 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 158 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 117 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 225 76 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 20 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(219 182 0 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(163 135 0 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(46 38 0 / var(--tw-bg-opacity));
}

.ui-selected\:bg-yellow-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-100[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-200[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-300[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-400[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-50[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-500[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-600[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-700[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-800[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-900[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.ui-selected\:bg-zinc-950[data-headlessui-state~="selected"] {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.ui-selected\:text-amber-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.ui-selected\:text-amber-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.ui-selected\:text-blue-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.ui-selected\:text-cyan-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.ui-selected\:text-emerald-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.ui-selected\:text-fuchsia-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.ui-selected\:text-gray-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.ui-selected\:text-green-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.ui-selected\:text-green-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.ui-selected\:text-green-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.ui-selected\:text-green-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.ui-selected\:text-green-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

.ui-selected\:text-green-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.ui-selected\:text-green-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.ui-selected\:text-green-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.ui-selected\:text-green-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.ui-selected\:text-green-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.ui-selected\:text-green-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.ui-selected\:text-indigo-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.ui-selected\:text-lime-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.ui-selected\:text-neutral-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

.ui-selected\:text-orange-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(67 20 7 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.ui-selected\:text-pink-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.ui-selected\:text-purple-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.ui-selected\:text-red-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.ui-selected\:text-red-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.ui-selected\:text-red-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.ui-selected\:text-red-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.ui-selected\:text-red-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 242 242 / var(--tw-text-opacity));
}

.ui-selected\:text-red-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.ui-selected\:text-red-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.ui-selected\:text-red-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.ui-selected\:text-red-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.ui-selected\:text-red-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

.ui-selected\:text-red-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(69 10 10 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.ui-selected\:text-rose-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.ui-selected\:text-sky-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.ui-selected\:text-slate-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.ui-selected\:text-stone-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

.ui-selected\:text-teal-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

.ui-selected\:text-tremor-brand[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.ui-selected\:text-tremor-content-strong[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.ui-selected\:text-violet-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 252 239 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 246 198 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 239 158 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 232 117 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 225 76 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(255 216 20 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(219 182 0 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(163 135 0 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(46 38 0 / var(--tw-text-opacity));
}

.ui-selected\:text-yellow-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-100[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-200[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-300[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-400[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-50[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-500[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-600[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-700[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-800[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-900[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.ui-selected\:text-zinc-950[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

.ui-selected\:shadow-tremor-input[data-headlessui-state~="selected"] {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-b-2 {
  border-bottom-width: 2px;
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-amber-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-blue-950 {
  --tw-border-opacity: 1;
  border-color: rgb(23 37 84 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-cyan-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-200 {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-900 {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-emerald-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-100 {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-200 {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-300 {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-400 {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-500 {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-600 {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-700 {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-800 {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-900 {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-fuchsia-950 {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-gray-950 {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 244 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-green-950 {
  --tw-border-opacity: 1;
  border-color: rgb(5 46 22 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-indigo-950 {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-lime-950 {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-neutral-950 {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 237 213 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 215 170 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 186 116 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 146 60 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 247 237 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-600 {
  --tw-border-opacity: 1;
  border-color: rgb(234 88 12 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-700 {
  --tw-border-opacity: 1;
  border-color: rgb(194 65 12 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgb(154 52 18 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-900 {
  --tw-border-opacity: 1;
  border-color: rgb(124 45 18 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-orange-950 {
  --tw-border-opacity: 1;
  border-color: rgb(67 20 7 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-pink-950 {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-purple-950 {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 226 226 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 165 165 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgb(248 113 113 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgb(254 242 242 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgb(127 29 29 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-red-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 10 10 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-600 {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-800 {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-900 {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-rose-950 {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-slate-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-400 {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-600 {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-700 {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-900 {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-stone-950 {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-100 {
  --tw-border-opacity: 1;
  border-color: rgb(204 251 241 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-200 {
  --tw-border-opacity: 1;
  border-color: rgb(153 246 228 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-300 {
  --tw-border-opacity: 1;
  border-color: rgb(94 234 212 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-400 {
  --tw-border-opacity: 1;
  border-color: rgb(45 212 191 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 250 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-600 {
  --tw-border-opacity: 1;
  border-color: rgb(13 148 136 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-700 {
  --tw-border-opacity: 1;
  border-color: rgb(15 118 110 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-900 {
  --tw-border-opacity: 1;
  border-color: rgb(19 78 74 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-teal-950 {
  --tw-border-opacity: 1;
  border-color: rgb(4 47 46 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-tremor-border {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-tremor-brand {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-200 {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-300 {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-400 {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-50 {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-700 {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-800 {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-900 {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-violet-950 {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 252 239 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(255 246 198 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(255 239 158 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(255 232 117 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(255 225 76 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(255 216 20 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgb(219 182 0 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgb(163 135 0 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgb(46 38 0 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-yellow-950 {
  --tw-border-opacity: 1;
  border-color: rgb(66 32 6 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-700 {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-800 {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-900 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:border-zinc-950 {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-cyan-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-fuchsia-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-green-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-indigo-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-orange-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-pink-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-purple-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-red-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 10 10 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-rose-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-stone-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-teal-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-tremor-background {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-tremor-background-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-violet-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 252 239 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 198 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 239 158 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 232 117 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 225 76 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 216 20 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 182 0 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 135 0 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 38 0 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-yellow-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-blue-950 {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-fuchsia-950 {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-green-950 {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-100 {
  --tw-text-opacity: 1;
  color: rgb(255 237 213 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-200 {
  --tw-text-opacity: 1;
  color: rgb(254 215 170 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-300 {
  --tw-text-opacity: 1;
  color: rgb(253 186 116 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-50 {
  --tw-text-opacity: 1;
  color: rgb(255 247 237 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-900 {
  --tw-text-opacity: 1;
  color: rgb(124 45 18 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-orange-950 {
  --tw-text-opacity: 1;
  color: rgb(67 20 7 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-pink-950 {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-purple-950 {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgb(254 242 242 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(127 29 29 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-red-950 {
  --tw-text-opacity: 1;
  color: rgb(69 10 10 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-300 {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-50 {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-rose-950 {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-slate-950 {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-400 {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-stone-950 {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-100 {
  --tw-text-opacity: 1;
  color: rgb(204 251 241 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-200 {
  --tw-text-opacity: 1;
  color: rgb(153 246 228 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-300 {
  --tw-text-opacity: 1;
  color: rgb(94 234 212 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-400 {
  --tw-text-opacity: 1;
  color: rgb(45 212 191 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 250 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-600 {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-700 {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-900 {
  --tw-text-opacity: 1;
  color: rgb(19 78 74 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-teal-950 {
  --tw-text-opacity: 1;
  color: rgb(4 47 46 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-tremor-brand {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-tremor-content-strong {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-300 {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-50 {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-violet-950 {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(255 252 239 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(255 246 198 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(255 239 158 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(255 232 117 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(255 225 76 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(255 216 20 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(219 182 0 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(163 135 0 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(46 38 0 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-yellow-950 {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:shadow-tremor-input {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-not-selected\:text-dark-tremor-content[data-headlessui-state]:not([data-headlessui-state~="selected"]), .ui-not-selected\:text-tremor-content[data-headlessui-state]:not([data-headlessui-state~="selected"]) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.ui-not-selected\:hover\:text-tremor-content-emphasis:hover[data-headlessui-state]:not([data-headlessui-state~="selected"]) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

:where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .ui-not-selected\:text-dark-tremor-content:not([data-headlessui-state]), :where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .ui-not-selected\:text-tremor-content:not([data-headlessui-state]) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .ui-not-selected\:hover\:text-tremor-content-emphasis:hover:not([data-headlessui-state]) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.ui-active\:bg-tremor-background-muted[data-headlessui-state~="active"] {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.ui-active\:text-tremor-content-strong[data-headlessui-state~="active"] {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="active"]) .ui-active\:bg-tremor-background-muted {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="active"]) .ui-active\:text-tremor-content-strong {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

:where([data-headlessui-focus-visible]) .ui-focus-visible\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dark\:hidden:is(.dark *) {
  display: none;
}

.dark\:divide-black\/20:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  border-color: #0003;
}

.dark\:divide-dark-tremor-border:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-divide-opacity));
}

.dark\:divide-gray-600:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-divide-opacity));
}

.dark\:divide-gray-700:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity));
}

.dark\:divide-gray-800:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-divide-opacity));
}

.dark\:divide-neutral-800:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-divide-opacity));
}

.dark\:divide-slate-700:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-divide-opacity));
}

.dark\:divide-stone-700:is(.dark *) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-divide-opacity));
}

.dark\:border:is(.dark *) {
  border-width: 1px;
}

.dark\:border-none:is(.dark *) {
  border-style: none;
}

.dark\:\!border-white:is(.dark *) {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.dark\:border-dark-tremor-background:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(29 37 51 / var(--tw-border-opacity));
}

.dark\:border-dark-tremor-border:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.dark\:border-dark-tremor-brand:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.dark\:border-dark-tremor-brand-emphasis:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(168 128 247 / var(--tw-border-opacity));
}

.dark\:border-dark-tremor-brand-inverted:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.dark\:border-dark-tremor-brand-subtle:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(58 10 157 / var(--tw-border-opacity));
}

.dark\:border-gray-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.dark\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.dark\:border-gray-800:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.dark\:border-gray-900:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.dark\:border-primary-100:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(247 244 254 / var(--tw-border-opacity));
}

.dark\:border-primary-300:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(194 167 250 / var(--tw-border-opacity));
}

.dark\:border-red-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.dark\:border-secondary:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(234 128 255 / var(--tw-border-opacity));
}

.dark\:border-slate-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.dark\:border-slate-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.dark\:border-slate-800:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.dark\:border-stone-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.dark\:border-stone-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.dark\:border-b-stone-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.dark\:border-t-stone-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-top-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.dark\:\!bg-secondary:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 128 255 / var(--tw-bg-opacity)) !important;
}

.dark\:\!bg-slate-800:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}

.dark\:\!bg-slate-900:is(.dark *) {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.dark\:bg-black:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.dark\:bg-black\/10:is(.dark *) {
  background-color: #0000001a;
}

.dark\:bg-black\/20:is(.dark *) {
  background-color: #0003;
}

.dark\:bg-black\/30:is(.dark *) {
  background-color: #0000004d;
}

.dark\:bg-black\/50:is(.dark *) {
  background-color: #00000080;
}

.dark\:bg-black\/60:is(.dark *) {
  background-color: #0009;
}

.dark\:bg-blue-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.dark\:bg-blue-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-background:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 37 51 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-background-emphasis:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-background-muted:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(19 26 43 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-background-subtle:is(.dark *), .dark\:bg-dark-tremor-border:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-brand:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(141 90 245 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-brand-muted:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(39 6 104 / var(--tw-bg-opacity));
}

.dark\:bg-dark-tremor-brand-muted\/50:is(.dark *) {
  background-color: #27066880;
}

.dark\:bg-dark-tremor-brand-muted\/70:is(.dark *) {
  background-color: #270668b3;
}

.dark\:bg-dark-tremor-brand-subtle\/60:is(.dark *) {
  background-color: #3a0a9d99;
}

.dark\:bg-dark-tremor-content-subtle:is(.dark *), .dark\:bg-gray-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.dark\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.dark\:bg-gray-700\/20:is(.dark *) {
  background-color: #37415133;
}

.dark\:bg-gray-700\/30:is(.dark *) {
  background-color: #3741514d;
}

.dark\:bg-gray-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:bg-gray-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.dark\:bg-green-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.dark\:bg-green-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.dark\:bg-green-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.dark\:bg-neutral-200:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.dark\:bg-neutral-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.dark\:bg-neutral-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.dark\:bg-neutral-900\/50:is(.dark *) {
  background-color: #17171780;
}

.dark\:bg-orange-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.dark\:bg-primary-200:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(221 205 252 / var(--tw-bg-opacity));
}

.dark\:bg-primary-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(58 10 157 / var(--tw-bg-opacity));
}

.dark\:bg-red-400:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.dark\:bg-red-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.dark\:bg-red-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.dark\:bg-secondary:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(234 128 255 / var(--tw-bg-opacity));
}

.dark\:bg-secondary-300:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 188 255 / var(--tw-bg-opacity));
}

.dark\:bg-secondary-300\/30:is(.dark *) {
  background-color: #f1bcff4d;
}

.dark\:bg-secondary-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(155 76 153 / var(--tw-bg-opacity));
}

.dark\:bg-secondary-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(110 52 102 / var(--tw-bg-opacity));
}

.dark\:bg-slate-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.dark\:bg-slate-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.dark\:bg-slate-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.dark\:bg-slate-900\/10:is(.dark *) {
  background-color: #0f172a1a;
}

.dark\:bg-slate-950\/50:is(.dark *) {
  background-color: #02061780;
}

.dark\:bg-stone-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.dark\:bg-stone-700\/80:is(.dark *) {
  background-color: #44403ccc;
}

.dark\:bg-stone-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.dark\:bg-stone-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.dark\:bg-white:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.dark\:bg-white\/10:is(.dark *) {
  background-color: #ffffff1a;
}

.dark\:bg-white\/20:is(.dark *) {
  background-color: #fff3;
}

.dark\:bg-white\/40:is(.dark *) {
  background-color: #fff6;
}

.dark\:bg-white\/5:is(.dark *) {
  background-color: #ffffff0d;
}

.dark\:bg-white\/70:is(.dark *) {
  background-color: #ffffffb3;
}

.dark\:bg-yellow-900:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(46 38 0 / var(--tw-bg-opacity));
}

.dark\:bg-opacity-10:is(.dark *) {
  --tw-bg-opacity: .1;
}

.dark\:bg-opacity-5:is(.dark *) {
  --tw-bg-opacity: .05;
}

.dark\:\!fill-black:is(.dark *) {
  fill: #000 !important;
}

.dark\:fill-dark-tremor-content:is(.dark *) {
  fill: #6b7280;
}

.dark\:fill-dark-tremor-content-emphasis:is(.dark *) {
  fill: #e5e7eb;
}

.dark\:fill-white:is(.dark *) {
  fill: #fff;
}

.dark\:stroke-dark-tremor-background:is(.dark *) {
  stroke: #1d2533;
}

.dark\:stroke-dark-tremor-border:is(.dark *) {
  stroke: #1f2937;
}

.dark\:stroke-dark-tremor-brand:is(.dark *) {
  stroke: #8d5af5;
}

.dark\:stroke-dark-tremor-brand-muted:is(.dark *) {
  stroke: #270668;
}

.dark\:text-black:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-brand:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-brand-emphasis:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(168 128 247 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-brand-inverted:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-content:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-content-emphasis:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-content-strong:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.dark\:text-dark-tremor-content-subtle:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.dark\:text-gray-100:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.dark\:text-gray-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.dark\:text-gray-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.dark\:text-gray-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.dark\:text-gray-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:text-gray-600:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.dark\:text-gray-700:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.dark\:text-green-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.dark\:text-green-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.dark\:text-lime-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.dark\:text-orange-600:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.dark\:text-primary-100:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(247 244 254 / var(--tw-text-opacity));
}

.dark\:text-primary-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(221 205 252 / var(--tw-text-opacity));
}

.dark\:text-red-100:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.dark\:text-red-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.dark\:text-red-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.dark\:text-red-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.dark\:text-red-600:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.dark\:text-secondary-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(246 217 255 / var(--tw-text-opacity));
}

.dark\:text-secondary-600:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(199 99 204 / var(--tw-text-opacity));
}

.dark\:text-slate-100:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.dark\:text-slate-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.dark\:text-stone-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.dark\:text-tremor-content-emphasis:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.dark\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:text-white\/70:is(.dark *) {
  color: #ffffffb3;
}

.dark\:text-white\/80:is(.dark *) {
  color: #fffc;
}

.dark\:text-yellow-600:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 216 20 / var(--tw-text-opacity));
}

.dark\:accent-dark-tremor-brand:is(.dark *) {
  accent-color: #8d5af5;
}

.dark\:opacity-25:is(.dark *) {
  opacity: .25;
}

.dark\:opacity-60:is(.dark *) {
  opacity: .6;
}

.dark\:opacity-70:is(.dark *) {
  opacity: .7;
}

.dark\:opacity-80:is(.dark *) {
  opacity: .8;
}

.dark\:shadow-dark-tremor-card:is(.dark *) {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark\:shadow-dark-tremor-dropdown:is(.dark *) {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark\:shadow-dark-tremor-input:is(.dark *) {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark\:shadow-none:is(.dark *) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark\:outline-dark-tremor-brand:is(.dark *) {
  outline-color: #8d5af5;
}

.dark\:outline-gray-700:is(.dark *) {
  outline-color: #374151;
}

.dark\:ring-2:is(.dark *) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dark\:ring-dark-tremor-brand-inverted:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(3 7 18 / var(--tw-ring-opacity));
}

.dark\:ring-dark-tremor-brand-muted:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(39 6 104 / var(--tw-ring-opacity));
}

.dark\:ring-dark-tremor-ring:is(.dark *), .dark\:ring-gray-50\/10:is(.dark *) {
  --tw-ring-color: #f9fafb1a;
}

.dark\:ring-gray-900:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity));
}

.dark\:ring-primary-100:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(247 244 254 / var(--tw-ring-opacity));
}

.dark\:ring-red-500:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

.dark\:ring-secondary:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 128 255 / var(--tw-ring-opacity));
}

.dark\:ring-opacity-60:is(.dark *) {
  --tw-ring-opacity: .6;
}

.dark\:ring-offset-gray-800:is(.dark *) {
  --tw-ring-offset-color: #1f2937;
}

.dark\:\[--dot-bg\:\#111827\]:is(.dark *) {
  --dot-bg: #111827;
}

.dark\:\[--dot-color\:white\]:is(.dark *) {
  --dot-color: white;
}

.dark\:placeholder\:text-dark-tremor-content:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:placeholder\:text-dark-tremor-content-subtle:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.dark\:placeholder\:text-red-500:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.dark\:placeholder\:text-tremor-content:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:placeholder\:text-tremor-content-subtle:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.dark\:placeholder\:text-white:is(.dark *)::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.dark\:after\:border-t-neutral-200:is(.dark *):after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-top-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.dark\:focus-within\:border-secondary:focus-within:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(234 128 255 / var(--tw-border-opacity));
}

.dark\:focus-within\:ring-secondary:focus-within:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 128 255 / var(--tw-ring-opacity));
}

.dark\:hover\:border-dark-tremor-brand-emphasis:hover:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(168 128 247 / var(--tw-border-opacity));
}

.hover\:dark\:dark\:border-slate-500:is(.dark *):is(.dark *):hover {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.dark\:hover\:bg-black\/95:hover:is(.dark *) {
  background-color: #000000f2;
}

.dark\:hover\:bg-dark-tremor-background-muted:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(19 26 43 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-dark-tremor-background-subtle:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-dark-tremor-background-subtle\/40:hover:is(.dark *) {
  background-color: #1f293766;
}

.dark\:hover\:bg-dark-tremor-brand-emphasis:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(168 128 247 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-dark-tremor-brand-faint:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(11 18 41 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-gray-600:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-gray-700:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-gray-800:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-secondary:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(234 128 255 / var(--tw-bg-opacity));
}

.dark\:hover\:bg-slate-800\/80:hover:is(.dark *) {
  background-color: #1e293bcc;
}

.hover\:dark\:bg-gray-600:is(.dark *):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.hover\:dark\:bg-stone-700\/80:is(.dark *):hover {
  background-color: #44403ccc;
}

.dark\:hover\:bg-opacity-20:hover:is(.dark *) {
  --tw-bg-opacity: .2;
}

.dark\:hover\:text-dark-tremor-brand-emphasis:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(168 128 247 / var(--tw-text-opacity));
}

.dark\:hover\:text-dark-tremor-content:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:hover\:text-gray-200:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.dark\:hover\:text-slate-200:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.dark\:hover\:text-stone-200:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.dark\:hover\:text-tremor-content:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:hover\:text-tremor-content-emphasis:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.dark\:hover\:text-white:hover:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:dark\:text-dark-tremor-content:is(.dark *):hover {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.dark\:focus\:border-dark-tremor-brand-subtle:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(58 10 157 / var(--tw-border-opacity));
}

.dark\:focus\:border-secondary:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(234 128 255 / var(--tw-border-opacity));
}

.focus\:dark\:border-dark-tremor-brand-subtle:is(.dark *):focus {
  --tw-border-opacity: 1;
  border-color: rgb(58 10 157 / var(--tw-border-opacity));
}

.dark\:focus\:ring-0:focus:is(.dark *) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dark\:focus\:ring-dark-tremor-brand-muted:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(39 6 104 / var(--tw-ring-opacity));
}

.dark\:focus\:ring-primary-500:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 90 245 / var(--tw-ring-opacity));
}

.dark\:focus\:ring-secondary:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 128 255 / var(--tw-ring-opacity));
}

.dark\:focus\:ring-secondary-600:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(199 99 204 / var(--tw-ring-opacity));
}

.focus\:dark\:ring-dark-tremor-brand-muted:is(.dark *):focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(39 6 104 / var(--tw-ring-opacity));
}

.group:hover .dark\:group-hover\:border-gray-700:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.group\/table-row:hover .dark\:group-hover\/table-row\:bg-gray-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:dark\:bg-dark-tremor-brand-subtle\/70:is(.dark *) {
  background-color: #3a0a9db3;
}

.group:hover .dark\:group-hover\:text-dark-tremor-content-emphasis:is(.dark *), .group:hover .dark\:group-hover\:text-gray-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.group:hover .dark\:group-hover\:text-white:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group.has-error .group-\[\.has-error\]\:dark\:border-red-500:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.group.has-error .group-\[\.has-error\]\:dark\:text-red-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(254 202 202 / var(--tw-text-opacity));
}

.group.has-error .group-\[\.has-error\]\:dark\:focus-within\:border-red-500:focus-within:is(.dark *), .group.has-error .group-\[\.has-error\]\:dark\:focus\:border-red-500:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.aria-selected\:dark\:\!bg-dark-tremor-background-subtle:is(.dark *)[aria-selected="true"] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}

.dark\:aria-selected\:bg-dark-tremor-background-emphasis[aria-selected="true"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.dark\:aria-selected\:text-dark-tremor-brand-inverted[aria-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.dark\:aria-selected\:text-dark-tremor-content-inverted[aria-selected="true"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.dark\:ui-selected\:border-dark-tremor-border[data-headlessui-state~="selected"]:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.ui-selected\:dark\:border-dark-tremor-brand:is(.dark *)[data-headlessui-state~="selected"] {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.dark\:ui-selected\:bg-dark-tremor-background[data-headlessui-state~="selected"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 37 51 / var(--tw-bg-opacity));
}

.dark\:ui-selected\:bg-dark-tremor-background-muted[data-headlessui-state~="selected"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(19 26 43 / var(--tw-bg-opacity));
}

.dark\:ui-selected\:text-dark-tremor-brand[data-headlessui-state~="selected"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.dark\:ui-selected\:text-dark-tremor-content-strong[data-headlessui-state~="selected"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.ui-selected\:dark\:text-dark-tremor-brand:is(.dark *)[data-headlessui-state~="selected"] {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

.dark\:ui-selected\:shadow-dark-tremor-input[data-headlessui-state~="selected"]:is(.dark *) {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:where([data-headlessui-state~="selected"]) .dark\:ui-selected\:border-dark-tremor-border:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:dark\:border-dark-tremor-brand:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

:where([data-headlessui-state~="selected"]) .dark\:ui-selected\:bg-dark-tremor-background:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(29 37 51 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .dark\:ui-selected\:bg-dark-tremor-background-muted:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(19 26 43 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="selected"]) .dark\:ui-selected\:text-dark-tremor-brand:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .dark\:ui-selected\:text-dark-tremor-content-strong:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .ui-selected\:dark\:text-dark-tremor-brand:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(141 90 245 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="selected"]) .dark\:ui-selected\:shadow-dark-tremor-input:is(.dark *) {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark\:ui-not-selected\:text-dark-tremor-content[data-headlessui-state]:not([data-headlessui-state~="selected"]):is(.dark *), .ui-not-selected\:dark\:text-dark-tremor-content:is(.dark *)[data-headlessui-state]:not([data-headlessui-state~="selected"]) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.ui-not-selected\:dark\:hover\:border-dark-tremor-content-emphasis:hover:is(.dark *)[data-headlessui-state]:not([data-headlessui-state~="selected"]) {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.dark\:ui-not-selected\:hover\:text-dark-tremor-content-emphasis:hover[data-headlessui-state]:not([data-headlessui-state~="selected"]):is(.dark *), .ui-not-selected\:dark\:hover\:text-dark-tremor-content-emphasis:hover:is(.dark *)[data-headlessui-state]:not([data-headlessui-state~="selected"]) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

:where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .dark\:ui-not-selected\:text-dark-tremor-content:not([data-headlessui-state]):is(.dark *), :where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .ui-not-selected\:dark\:text-dark-tremor-content:is(.dark *):not([data-headlessui-state]) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

:where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .ui-not-selected\:dark\:hover\:border-dark-tremor-content-emphasis:hover:is(.dark *):not([data-headlessui-state]) {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

:where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .dark\:ui-not-selected\:hover\:text-dark-tremor-content-emphasis:hover:not([data-headlessui-state]):is(.dark *), :where([data-headlessui-state]:not([data-headlessui-state~="selected"])) .ui-not-selected\:dark\:hover\:text-dark-tremor-content-emphasis:hover:is(.dark *):not([data-headlessui-state]) {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.dark\:ui-active\:bg-dark-tremor-background-muted[data-headlessui-state~="active"]:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(19 26 43 / var(--tw-bg-opacity));
}

.dark\:ui-active\:text-dark-tremor-content-strong[data-headlessui-state~="active"]:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

:where([data-headlessui-state~="active"]) .dark\:ui-active\:bg-dark-tremor-background-muted:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(19 26 43 / var(--tw-bg-opacity));
}

:where([data-headlessui-state~="active"]) .dark\:ui-active\:text-dark-tremor-content-strong:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }
}

@media (width >= 768px) {
  .md\:static {
    position: static;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:mr-64 {
    margin-right: 16rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:max-h-none {
    max-height: none;
  }

  .md\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pr-3\.5 {
    padding-right: .875rem;
  }

  .md\:pt-2 {
    padding-top: .5rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1024px) {
  .lg\:sticky {
    position: sticky;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:ml-72 {
    margin-left: 18rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:\!w-auto {
    width: auto !important;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:max-w-\[200px\] {
    max-width: 200px;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:opacity-5 {
    opacity: .05;
  }

  .lg\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }
}

@media print {
  .print\:hidden {
    display: none;
  }

  .print\:break-inside-avoid {
    break-inside: avoid;
  }

  .print\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button {
  appearance: none;
}

.\[\&\:\:-webkit-outer-spin-button\]\:appearance-none::-webkit-outer-spin-button {
  appearance: none;
}

.\[\&\:\:-webkit-scrollbar\]\:hidden::-webkit-scrollbar {
  display: none;
}

.\[\&\>a\]\:font-semibold > a {
  font-weight: 600;
}

.\[\&\>a\]\:text-gray-900 > a {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.\[\&\>a\]\:dark\:text-white:is(.dark *) > a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.\[\&\>button\:first-child\]\:bg-white > button:first-child {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus\:\[\&\>button\:first-child\]\:border-primary > button:first-child:focus {
  --tw-border-opacity: 1;
  border-color: rgb(141 90 245 / var(--tw-border-opacity));
}

.focus\:\[\&\>button\:first-child\]\:ring-1 > button:first-child:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:\[\&\>button\:first-child\]\:ring-primary > button:first-child:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(141 90 245 / var(--tw-ring-opacity));
}

.dark\:\[\&\>button\:first-child\]\:border-gray-700 > button:first-child:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.dark\:\[\&\>button\:first-child\]\:bg-gray-800 > button:first-child:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.dark\:focus\:\[\&\>button\:first-child\]\:border-secondary > button:first-child:focus:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(234 128 255 / var(--tw-border-opacity));
}

.dark\:focus\:\[\&\>button\:first-child\]\:ring-secondary > button:first-child:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 128 255 / var(--tw-ring-opacity));
}

.\[\&\>div\]\:flex-1 > div {
  flex: 1;
}

.\[\&\>p\>a\]\:underline > p > a {
  text-decoration-line: underline;
}

.\[\&\>span\]\:mr-2 > span {
  margin-right: .5rem;
}

.\[\&\>span\]\:text-transparent > span {
  color: #0000;
}

.\[\&\>strong\]\:font-semibold > strong {
  font-weight: 600;
}

.\[\&\>svg\:not\(\[data-loader\]\)\]\:text-transparent > svg:not([data-loader]) {
  color: #0000;
}

.\[\&\>svg\]\:m-0 > svg {
  margin: 0;
}

.\[\&\>svg\]\:-mx-0\.5 > svg {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.\[\&\>svg\]\:-mx-1 > svg {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.\[\&\>svg\]\:-mx-2 > svg {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.\[\&\>svg\]\:-my-3 > svg {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.\[\&\>svg\]\:-my-4 > svg {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.\[\&\>svg\]\:-my-5 > svg {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.\[\&\>svg\]\:-my-6 > svg {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.\[\&\>svg\]\:h-3 > svg {
  height: .75rem;
}

.\[\&\>svg\]\:h-4 > svg {
  height: 1rem;
}

.\[\&\>svg\]\:h-5 > svg {
  height: 1.25rem;
}

.\[\&\>svg\]\:h-6 > svg {
  height: 1.5rem;
}

.\[\&\>svg\]\:w-3 > svg {
  width: .75rem;
}

.\[\&\>svg\]\:w-4 > svg {
  width: 1rem;
}

.\[\&\>svg\]\:w-5 > svg {
  width: 1.25rem;
}

.\[\&\>svg\]\:w-6 > svg {
  width: 1.5rem;
}

.\[\&\>td\]\:py-3 > td {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.\[\&\>th\]\:pb-2 > th {
  padding-bottom: .5rem;
}

.\[\&_button\]\:\!h-10 button, .\[\&_input\]\:\!h-10 input, .\[\&_select\]\:\!h-10 select {
  height: 2.5rem !important;
}

.\[\&_svg\]\:fill-black svg {
  fill: #000;
}

.\[\&_svg\]\:fill-stone-500 svg {
  fill: #78716c;
}

.\[\&_svg\]\:dark\:fill-stone-400:is(.dark *) svg {
  fill: #a8a29e;
}

.\[\&_svg\]\:dark\:fill-white:is(.dark *) svg {
  fill: #fff;
}

.\[\&_td\]\:px-4 td, .\[\&_th\]\:px-4 th {
  padding-left: 1rem;
  padding-right: 1rem;
}
/*# sourceMappingURL=index.127d9a65.css.map */
